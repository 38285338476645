import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormControl } from '@angular/forms';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { ERROR_MSG_AT_LEAST_ONE_CHECKED } from 'src/app/services/formly-builder/constants/general.constants';
import { SectionEnum } from 'src/app/services/formly-builder/section.config';
export class WindFrameworkSectionModel extends FormlySectionModelAbstract {
  constructor(private formlyService: FormlyBuilderService) {
    super({
      type: 'rf-sheet-section',
      props: {
        label: 'Framework',
        subTitle: 'Framework',
        title: 'Wind Report',
        isCollapsable: true,
        isCollapsed: false,
        isDisableable: true,
        actionButtons: [ { section: 'windReport', action: 'showFormlySheet', label: 'Add', isDisabled: false, service: 'formlyWrapperService' } ],
        sheetButtons: [
          {
            section: 'windReport',
            action: 'onClose',
            label: 'Close',
            service: 'windFrameworkService',
            className: 'close-btn medium-btn',
            passInitialModel: true,
          },
          {
            section: 'windReport',
            action: 'onApply',
            hasConfirmationPopup: true,
            label: 'Apply',
            service: 'windFrameworkService',
            className: 'apply-btn large-btn',
          },
        ],
      },
      key: 'windFramework',
      fieldGroup: [
        {
          wrappers: ['simple-section-wrapper'],
          className: 'outside-section wr-framework-container', 
          props: {
            label: 'What type(s) of roof to wall connections are being used in this building?',
            // isCollapsed: false,
            isCollapsable: true,
            // showError: true,   
            checkTouchedControl: true,
          },
          fieldGroup: [
            {
              type: 'rf-predominant-block',
              props: {
                // No label: margins are adapted to show inside a section wrapper
                options: [],
                path: 'windReport.windFramework',
                errorKey: 'windFramework',
                errorMessage: 'At least one Roof to Wall Connection Type must be selected',
                doesNotApplyCheckboxValidatorErrorMessage: 'No Discernible cannot be selected with other connection types. Please correct type selections.',
                predominatErrorMessage: 'One Roof to Wall Connection Type must be marked as predominant'
              },
              hooks: {
                onInit: (field) => formlyService.windFrameworkService.initCheckboxes(field, [
                  { label: 'Mechanical (welded, bolted, strapped, or anchor bolts)', checkboxKey: 'isRoofToWallConnectionTypeMechanical', predominantKey: 'isRoofToWallConnectionTypeMechanicalPredominant' },
                  { label: 'Gravity Heavy Framing (relying on weight of roof framing)', checkboxKey: 'isRoofToWallConnectionTypeGravityHeavyFraming', predominantKey: 'isRoofToWallConnectionTypeGravityHeavyFramingPredominant' },
                  { label: 'Friction (nails and/or screws)', checkboxKey: 'isRoofToWallConnectionTypeFriction', predominantKey: 'isRoofToWallConnectionTypeFrictionPredominant' },
                  { label: 'Gravity Light Framing (relying on wt. of roof framing)', checkboxKey: 'isRoofToWallConnectionTypeGravityLightFraming', predominantKey: 'isRoofToWallConnectionTypeGravityLightFramingPredominant' },
                  { label: 'Adhesive (use of structural expoxy)', checkboxKey: 'isRoofToWallConnectionTypeAdhesive', predominantKey: 'isRoofToWallConnectionTypeAdhesivePredominant' },
                  { label: 'Not Discernable', checkboxKey: 'isRoofToWallConnectionTypeNotDiscernable', predominantKey: 'isRoofToWallConnectionTypeNotDiscernablePredominant', isDisableAll: false, isDoesNotApplyField: true },
                ])
              },
              fieldGroup: [
                { key: 'isRoofToWallConnectionTypeMechanical' },
                { key: 'isRoofToWallConnectionTypeMechanicalPredominant' },    
                { key: 'isRoofToWallConnectionTypeGravityHeavyFraming' },
                { key: 'isRoofToWallConnectionTypeGravityHeavyFramingPredominant' },
                { key: 'isRoofToWallConnectionTypeFriction' },
                { key: 'isRoofToWallConnectionTypeFrictionPredominant' },
                { key: 'isRoofToWallConnectionTypeGravityLightFraming' },
                { key: 'isRoofToWallConnectionTypeGravityLightFramingPredominant' },                
                { key: 'isRoofToWallConnectionTypeAdhesive' },
                { key: 'isRoofToWallConnectionTypeAdhesivePredominant' },
                { key: 'isRoofToWallConnectionTypeNotDiscernable' },
                { key: 'isRoofToWallConnectionTypeNotDiscernablePredominant' },                
              ],
              validators: {
                validation: [ {
                  name: 'at-least-one-boolean',
                  options: {
                    list: [ 'isRoofToWallConnectionTypeMechanical',
                      'isRoofToWallConnectionTypeGravityHeavyFraming',
                      'isRoofToWallConnectionTypeFriction',
                      'isRoofToWallConnectionTypeGravityLightFraming',
                      'isRoofToWallConnectionTypeAdhesive',
                      'isRoofToWallConnectionTypeNotDiscernable'
                    ],
                    errorName: 'atLeastOneCheckedValidator',
                    fieldFlagNeeded: 'isItemChecked',
                    message: 'At least one Roof to Wall Connection Type must be selected',
                  }
                },
                {
                  name: 'at-least-one-boolean',
                  options: {
                    list: [ 'isRoofToWallConnectionTypeMechanicalPredominant',
                      'isRoofToWallConnectionTypeGravityHeavyFramingPredominant',
                      'isRoofToWallConnectionTypeFrictionPredominant',
                      'isRoofToWallConnectionTypeGravityLightFramingPredominant',
                      'isRoofToWallConnectionTypeAdhesivePredominant',
                      'isRoofToWallConnectionTypeNotDiscernablePredominant'
                    ],
                    errorName: 'atLeastOnePredominantValidator',
                    fieldFlagNeeded: 'isPredominant',
                    message: 'One Roof to Wall Connection Type must be marked as predominant',
                  }
                },
                {
                  name: 'does-not-apply-checkbox-validator',
                  options: {
                    list1: [
                      'isRoofToWallConnectionTypeNotDiscernable'
                    ],
                    list2: [
                      'isRoofToWallConnectionTypeMechanical',
                      'isRoofToWallConnectionTypeGravityHeavyFraming',
                      'isRoofToWallConnectionTypeFriction',
                      'isRoofToWallConnectionTypeGravityLightFraming',
                      'isRoofToWallConnectionTypeAdhesive'
                    ],
                    errorName: 'doesNotApplyCheckboxValidator',
                    message: 'No Discernible cannot be selected with other connection types. Please correct type selections.'
                  }
                }
                ]
              }
            },
            {
              key: 'primaryRoofSystemSpan',              
              type: 'simple-select',
              className: 'select-full-width',
              props: {
                label: 'Span of the Primary Roof System',
                required: true,
                isSectionLabelNormal: true,
                firstColSize: 8,
                showError: false,
                isCardEnabled: true,
                isHorizontalOrientation: true,
                isAsteriskNotRequired: true,
                options: [
                  { value: "NDSC", label: 'Not discernable' },
                  { value: "<= 25'", label: '<= 25\'' }, 
                  { value: "> 25' - 50'", label: '> 25\' to 50\'' },
                  { value: ">50' - 75'", label: '> 50\' to 75\'' },
                  { value: "> 75' - 100'", label: '> 75\' to 100\'' },
                  { value: "> 100'", label: '> 100\'' }
                ],
              },
            },
            {
              key: 'firstStoryHeight',              
              type: 'simple-select',
              className: 'select-full-width',
              props: {
                label: 'Height of the First Story',
                required: true,
                isSectionLabelNormal: true,
                firstColSize: 8,
                showError: false,
                isCardEnabled: true,
                isHorizontalOrientation: true,
                isAsteriskNotRequired: true,
                options: [
                  { value: "<= 10'", label: '<= 10\'' },
                  { value: "> 10' - 15'", label: '> 10\' to 15\'' },
                  { value: ">15' - 20'", label: '> 15\' to 20\'' },
                  { value: "> 20'", label: '> 20\'' },
                ],
              },
              hooks: {
                onInit: (field) => formlyService.windFrameworkService.setFirstStoryHeight(field)
              },
            },
            {
              key: 'typicalUpperStoryHeight',              
              type: 'simple-select',
              className: 'select-full-width margin-bottom-small',
              props: {
                label: 'Typical Upper Story Height',
                required: true,
                isSectionLabelNormal: true,
                firstColSize: 8,
                showError: false,
                isCardEnabled: true,
                isHorizontalOrientation: true,
                isAsteriskNotRequired: true,
                options: [
                  { value: "NAPP", label: 'Not applicable' },
                  { value: "<= 10'", label: '<= 10\'' },
                  { value: ">10' - 15'", label: '> 10\' to 15\'' },
                  { value: ">15' - 20'", label: '> 15\' to 20\'' },
                  { value: "> 20'", label: '> 20\'' },
                ],
              },
              hooks: {
                onInit: (field) => formlyService.windFrameworkService.setTypicalUpperStoryHeight(field)
              },
            },
            { key: 'hasRoofToWallConnection' }
          ],
        },
      ],
      expressions: {
        // Hide the whole section if WR is disabled
        hide: (field: FormlyFieldConfig) => {
          const disabledField = field?.options?.formState?.isMainSectionDisabled?.filter(val => val.sectionId === SectionEnum.FR_WIND);
          if (disabledField.length==1) {
            return true;
          }
           else if (disabledField.length==0){
            return false;
          }
        }
      },            
    });
  }
}
