/* eslint-disable @typescript-eslint/dot-notation */
import {  Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { FormlyBuilderService } from './formly-builder.service';

@Injectable({
  providedIn: 'root',
})
export class FormlyPublicProtectionAreaService  {

  public searchResults = [];
  selectDataStream = new Subject();

  constructor(private http: HttpClient, 
    public formlyBuilderService: FormlyBuilderService) {
  }
  
  getSearchResults(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.getCities('ppa-list')?.subscribe(
        (response) => {
          const userState = this.formlyBuilderService.riskReport.model?.address?.mainAddress;
          const isCountyMatched = response?.find(src => (src?.CountyName?.toLowerCase() === userState.county?.trim().toLowerCase()) &&
            (src?.State?.toLowerCase() === userState.state?.trim().toLowerCase())) !== undefined;
          if (userState && isCountyMatched) {
            this.searchResults = response.filter((item) => (
              ((userState?.state?.trim().toLowerCase() === 'nx' && item?.State?.toLowerCase() === 'ny') ||
                (userState?.state?.trim().toLowerCase() === 'cx' && item?.State?.toLowerCase() === 'ca') ||
                item?.State?.toLowerCase() === userState?.state?.trim().toLowerCase()) &&
              item?.CountyName?.toLowerCase() === userState?.county?.trim().toLowerCase()
            ));
          } else if (userState && !isCountyMatched) {
            this.searchResults = response.filter((item) => (
              ((userState?.state?.trim().toLowerCase() === 'nx' && item?.State?.toLowerCase() === 'ny') ||
                (userState?.state?.trim().toLowerCase() === 'cx' && item?.State?.toLowerCase() === 'ca') ||
                item?.State?.toLowerCase() === userState?.state?.trim().toLowerCase())
            ));
          } else {
            this.searchResults = response;
          }
          // Process the response data if needed
          resolve(this.searchResults);
        },
        (error) => {
          // Handle errors if any
          reject(error);
        }
      );
    });
  }

  getCities(env: string): Observable<any> {
    return this.http
      .get('assets/json/risk/' + env + '.json');
  }

  filterCities(cities){
    // eslint-disable-next-line max-len
    return cities.length>3 ? this.searchResults.filter(city=>city.name.toLocaleLowerCase().startsWith(cities.toLocaleLowerCase())) : this.searchResults=[];
  }

  /**
   * Update territory option
   *
   * @param evnt selected fpa
   * @param address address with county and state code
   * @return territory options
   */
  getTerritory(evnt, address = null) {
    const userState = address ? address : this.formlyBuilderService.riskReport.model.address.mainAddress;
    this.getCities('ppa-list').subscribe((val) => {
      let newOptions = [];
      const isCountyMatched = val?.find(src => (src?.CountyName?.toLowerCase() === userState.county?.trim().toLowerCase()) &&
        (src?.State?.toLowerCase() === userState.state?.trim().toLowerCase())) !== undefined;
      if (isCountyMatched) {
        newOptions = val.reduce((unique, o) => {
          // reduce non duplicate, matches given input, matches user state and matches user county
          if (!unique.some(obj => obj?.label === o?.TerritoryCode) &&
            o?.FpaName?.toLowerCase().startsWith(evnt?.toLowerCase()) && (userState?.state &&
              ((userState.state?.toLowerCase() === 'nx' && o?.State?.toLowerCase() === 'ny') ||
                (userState.state?.toLowerCase() === 'cx' && o?.State?.toLowerCase() === 'ca') ||
                (o?.State?.toLowerCase() === userState.state?.trim().toLowerCase()))) &&
            (userState?.county && (o?.CountyName?.toLowerCase() === userState.county?.trim().toLowerCase()))
          ) {
            unique.push({ value: o.TerritoryCode, label: o.TerritoryCode });
          }
          unique.sort((a, b) => a.value - b.value);
          return unique;
        }, []);
      } else {
        newOptions = val.reduce((unique, o) => {
          if (!unique.some(obj => obj?.label === o?.TerritoryCode) &&
            o?.FpaName?.toLowerCase() === evnt?.trim()?.toLowerCase() && (userState?.state &&
              ((userState.state?.toLowerCase() === 'nx' && o?.State?.toLowerCase() === 'ny') ||
                (userState.state?.toLowerCase() === 'cx' && o?.State?.toLowerCase() === 'ca') ||
                (o?.State?.toLowerCase() === userState.state?.trim().toLowerCase())))) {
            unique.push({ value: o.TerritoryCode, label: o.TerritoryCode + ' (' + o?.CountyName + ')' });
          }
          unique.sort((a, b) => a.value - b.value);
          return unique;
        }, []);
      }
      this.selectDataStream.next(newOptions);
    });
  }
}
