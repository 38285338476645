import { RiskReportDataReports } from 'src/app/models/risk-report/risk-report-data.model';

export class FireSprinklerDataModel {
  static sectionName = 'fireSprinkler';

  evidenceOfFireSprinkler: boolean;

  static from(reports: RiskReportDataReports): FireSprinklerDataModel {
    return {
      evidenceOfFireSprinkler: reports.EvidenceOfFireSprinkler,
    };
  }

  static fromS3(reports: any): FireSprinklerDataModel {
    let evidenceOfFireSprinkler = null;

    if (reports.EvidenceOfFireSprinkler === 'Y') {
      evidenceOfFireSprinkler = 'true';
    } else if (reports.EvidenceOfFireSprinkler === 'N') {
      evidenceOfFireSprinkler = 'false';
    }

    return {
      evidenceOfFireSprinkler
    };
  }

  static to(model: any) {
    let evidenceOfFireSprinkler = null;
    let isSprinklerReportDeleted;
    if (model.evidenceOfFireSprinkler === 'true' || model.evidenceOfFireSprinkler === true) {
      evidenceOfFireSprinkler = 'Y';
    } else if (model.evidenceOfFireSprinkler === 'false' || model.evidenceOfFireSprinkler === false) {
      evidenceOfFireSprinkler = 'N';
    }
    return {
      EvidenceOfFireSprinkler: evidenceOfFireSprinkler
    };
  }

  /**
   * Check if the model has changed
   *
   * @param formlyModel Model from Formly
   * @param riskReportModel Model from the S3 Risk Report
   */
  static hasChanged(formlyModel: any, riskReportModel: any): boolean {
    if (riskReportModel.EvidenceOfFireSprinkler === undefined) {
      return true;
    }

    if (formlyModel.evidenceOfFireSprinkler === riskReportModel.EvidenceOfFireSprinkler) {
      return false;
    } else {
      return true;
    }
  }


}
