import { Storage as IonicLocalStorage } from '@ionic/storage-angular';
import { Constants } from '../util-service/constants';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { UtilService } from '../util-service/util.service';
import { VeriskLog } from '../util-service/verisk-log';

@Injectable({
    providedIn: 'root'
})
export class IonicStorageService {
    private orderQueue = new BehaviorSubject<any>({});
    public orderQueue$ = this.orderQueue.asObservable();

    constructor(private ionicStorage: IonicLocalStorage, private utilService: UtilService) {
        this.ionicStorage.create().then(() => {
            this.initializeOrderQueue();
        });
    }

    async initializeOrderQueue() {
        const orderQueue = await this.ionicStorage.get(Constants.orderQueue);
        if(!orderQueue) {
            this.ionicStorage.set(Constants.orderQueue, {});
        } else {
            this.orderQueue.next(orderQueue);
        }
    }

    storeOrderQueue(queue: any) {
        this.ionicStorage.set(Constants.orderQueue, queue).then(() => {
            this.orderQueue.next(queue);
        });
    }

    updateOrderQueue(orderDetails) {
        try{
            let orderQueue = this.orderQueue.value;

            orderQueue[orderDetails.id] = orderDetails;
            this.storeOrderQueue(orderQueue);
        }catch(e) {
            this.utilService.addLog(new VeriskLog('IonicStorageService updateOrderQueue failed','ERROR', this.updateOrderQueue.name,IonicStorageService.name, e, true));
        }
        
    }

    clearOrderQueue(){
        this.orderQueue.next({});
        this.ionicStorage.remove(Constants.orderQueue);
        this.ionicStorage.set(Constants.orderQueue, {});
    }
    
    deleteFromOrderQueue(orderDetails) {
        this.ionicStorage.set(Constants.orderQueue, orderDetails);
    }

    
 
}