import { SectionEnum } from 'src/app/services/formly-builder/section.config';

export class UsedNonSprinkleredObstructedAreasDataModel {
    static sectionName = 'nonSprinkleredObstructedArea';

    usedNonSprinkleredObstructedAreaId: string;
    reportId: string;
    isListedInFrf51: boolean;
    isWidelyScattered: boolean;
    widelyScatteredArea?: number;
    areaDescription?: string;
    areaMeetsItem?: string;
    areaComments?: string;

    static fromS3(usedNsObstructedAreasData: any): UsedNonSprinkleredObstructedAreasDataModel {

        return {
            usedNonSprinkleredObstructedAreaId: usedNsObstructedAreasData.UsedNonSprinkleredObstructedAreaId,
            reportId: usedNsObstructedAreasData.ReportId,
            isListedInFrf51: usedNsObstructedAreasData.IsListedInFrf51,
            isWidelyScattered: usedNsObstructedAreasData.IsWidelyScattered,
            widelyScatteredArea: usedNsObstructedAreasData.WidelyScatteredArea,
            areaDescription: usedNsObstructedAreasData.AreaDescription,
            areaMeetsItem: usedNsObstructedAreasData?.AreaMeetsItem?.trim(),
            areaComments: usedNsObstructedAreasData.AreaComments
        };
    }

    static to(model: any, sectionEnum?: SectionEnum) {
        return {
            UsedNonSprinkleredObstructedAreaId: model.usedNonSprinkleredObstructedAreaId,
            ReportId: model.reportId,
            IsListedInFrf51: model.isListedInFrf51?model.isListedInFrf51:false,
            IsWidelyScattered: model.isWidelyScattered,
            WidelyScatteredArea: model?.widelyScatteredArea ? Number(model?.widelyScatteredArea): model?.widelyScatteredArea,
            AreaDescription: model.areaDescription,
            AreaMeetsItem: model.areaMeetsItem ?  model.areaMeetsItem : '',
            AreaComments: model.areaComments
        };
    }

    /**
     * Check if the model has changed
     *
     * @param formlyModel Model from Formly
     * @param riskReportModel Model from the S3 Risk Report
     *
     */
    static hasChanged(formlyModel: any, riskReportModel: any, sectionEnum?: SectionEnum): boolean {
        return false;

    }
}
