/* eslint-disable @typescript-eslint/naming-convention */
import { EventEmitter, Injectable } from '@angular/core';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { FormlyBuilderService } from '../../formly-builder.service';
import { SectionEnum } from '../../section.config';
import { FormControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { NonSprinkleredAreasDataModel } from 'src/app/models/formly-builder/formly/full-risk/sprinkler/non-sprinklered-areas-data.model';
import { UtilService } from 'src/app/services/util-service/util.service';

@Injectable({
  providedIn: 'root',
})
export class NonSprinkleredAreasService extends SectionServiceAbstract {
  SaveToS3Event: EventEmitter<any> = new EventEmitter<any>();
  constructor(private formlyBuilderService: FormlyBuilderService, private utilService: UtilService) {
    super();
  }

  async updateData(model: any, riskReportData: any, sectionEnum?: SectionEnum) {
    const sectionData = model?.sprinklerReport?.asgrNonSprinkleredObstructedArea;
    this.formlyBuilderService.sprinklerReportService.clearScoreValues(model);
    let nonUsedArea = null;
    if (sectionData) {
      sectionData.unusedNonSprinkleredAreas.reportId =
        sectionData?.reportId ? sectionData?.reportId : riskReportData?.ReportIdentifier;
      nonUsedArea = NonSprinkleredAreasDataModel.to(sectionData);
    }

    const sprinklerData = {
      ...riskReportData.SprinklerReport,
      ...{ AsgrNonSprinkleredObstructedArea: nonUsedArea },
    };
    return { ...riskReportData, ...{ SprinklerReport: sprinklerData } };
  }

  loadData(sectionData: any[], reportInfo: any, isS3: boolean, model: any): any {
    return undefined;
  }

  async onApply(modalCtrl, field) {
    const formlyService: FormlyBuilderService = field.options.formState.service as FormlyBuilderService;
    const riskReport = field.options.formState.riskReportService.updateSectionData(
      SectionEnum.FR_S_NON_SPRINKLERED_AREAS,
      false,
      null,
      NonSprinkleredAreasService
    );
    field.props.isApplied = true;
    formlyService.nonSprinkleredAreaService.SaveToS3Event.emit();
    modalCtrl.dismiss(field, 'save');
  }

  async onClose(modalCtrl, field, initialModel) {   
    field.options.formState.service.riskReport.model.sprinklerReport.asgrNonSprinkleredObstructedArea =
      initialModel;
    field.options.formState.service.riskReport.model = {
      ...field.options.formState.service.riskReport.model,
    };
    field?.options?.formState?.service?.errorService?.closePreviousToaster();
    modalCtrl.dismiss(field, 'cancel');
  }

  /**
   *
   * @param control
   * @param field
   */
  affectedAreaValidation(control, field, key): boolean {
    const value = control?.value ? Number(control.value) : control?.value;
    const fieldValue = field?.form?.get(key)?.value
      ? Number(field?.form?.get(key)?.value)
      : field?.form?.get(key)?.value;
    if (value !== null || fieldValue !== null) {
      if (value > fieldValue) {
        return false;
      } else {
        return true;
      }
    }
    return true;
  }

  requiredAreaSpocValidation(control: FormControl, field: FormlyFieldConfig): boolean {
    const area = control?.value;
    const totalOccupancyArea = this.formlyBuilderService.occupantsService.getOccupancyTotalArea(field, true);
    if (area !== null && area && totalOccupancyArea !== null || totalOccupancyArea !== undefined) {
      if (Number(area) > Number(totalOccupancyArea)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  getTotalRoofArea(field, levelTypeCode) {
    const floorRoofEntries =
      field?.options?.formState?.service?.riskReport?.model?.floorsAndRoofs?.constructionEntries?.floorsAndRoofsLevels;
    let totalArea = 0;
    if (floorRoofEntries && floorRoofEntries.length > 0) {
      const allRoofEntries = floorRoofEntries.filter(
        (entry) => entry.levelTypeCode === levelTypeCode && !entry.isAreaCalc
      );
      if (allRoofEntries && allRoofEntries.length > 0) {
        totalArea = 0;
        allRoofEntries.forEach((roof) => {
          totalArea = totalArea + roof.area;
        });
      }
    }
    return totalArea;
  }

  checkRoofAreaValidation(control, field) {
    const area = control?.value;
    const totalRoofArea = this.getTotalRoofArea(field, 'ROOF');
    if (area !== null && area && totalRoofArea !== null) {
      if (Number(area) > Number(totalRoofArea)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  checkCombustibleConcealedArea(field) {
    const isNewRisk = this.formlyBuilderService.isNewRisk();
    const area =
      field.options.formState.service.riskReport.model?.secondaryConstructions?.combustibleConstruction
        ?.concealedRoofArea;
    if (isNewRisk && area) {
      return area;
    } else {
      return null;
    }
  }

  checkRoofContructionTypeAndDeck(field) {
    const floorRoofEntries =
      field?.options?.formState?.service?.riskReport?.model?.floorsAndRoofs?.constructionEntries?.floorsAndRoofsLevels;
    let hasContruction = false;
    let hasDeck = false;
    if (floorRoofEntries && floorRoofEntries.length > 0) {
      const allRoofEntries = floorRoofEntries.filter((entry) => entry.levelTypeCode === 'ROOF');
      if (allRoofEntries && allRoofEntries.length > 0) {
        allRoofEntries.forEach((roof) => {
          if (roof.constructionType === 'Fire Resistive') {
            hasContruction = true;
          }
          if (roof.deckConstruction === 'COMA') {
            hasDeck = true;
          }
        });
      }
    }
    if (hasContruction || hasDeck) {
      return true;
    } else {
      return false;
    }
  }

  getAreaModifierOptions() {
    return [
      { label: 'Sprinklers shut off during cold weather (1140e.1)', value: 'E1' },
      { label: 'Open canopies or platforms (1140e.2)', value: 'E2' },
      { label: ' C-1 or C-2 Occupancies (1140e.3)', value: 'E3' },
      { label: 'C-5 Occupancies (1140e.4)', value: 'E4' },
      { label: 'Obstructed (1140e.5)', value: 'E5' },
      { label: 'All Others', value: 'OTHER' },
    ];
  }
  generateObstructedAreaId(field) {
    if (field?.formControl?.value === null) {
      const id = this.utilService.uuid();
      field?.formControl.setValue(id);
    }
  }

  setReportId(field) {
    const value = field?.formControl?.value;
    const id = field?.options?.formState?.service?.riskReport?.report?.SprinklerReport?.ReportId;
    if (!value && id) {
      field?.formControl?.setValue(id);
    }
  }

  importNonSprinkleredArea(field) {
    if (field?.model?.length === 0) {
      const area = this.getNonSprinkleredAreaEntries();
      if (area !== null) {
        field.parent.model.usedNonSprinkleredObstructedAreas = area;
        field.options.formState.service.riskReport.model = {
          ...field.options.formState.service.riskReport.model,
        };
      }
    }
  }
  getNonSprinkleredAreaEntries() {
    const occupants = this.formlyBuilderService.riskReport.model?.occupants;
    const sectionOccupant = occupants
      ? 'section-occupants' in occupants
        ? occupants[ 'section-occupants' ]
        : null
      : null;
    let nonSrArea = null;
    if (sectionOccupant !== null && sectionOccupant) {
      const occupant = sectionOccupant?.occupants;
      const occupantsArray = [];
      if (occupant?.length > 0) {
        occupant.forEach((item) => {
          if (item && 'occupantLevels' in item && item?.occupantLevels?.length > 0) {
            item.occupantLevels.map((level) => {
              if (level && (level?.sprinklerDesign === 'None' || level?.sprinklerDesign === null ||
                (level.sprinklerDesign === "13R" && level.sprinklerType === "Non-Creditable") ||
                (level.sprinklerType === "Shut Off"))) {
                occupantsArray.push(item);
              }
            });
          }
        });
      }
      if (occupantsArray.length > 0) {
        const uniqueOccupant = occupantsArray.filter((item, index, self) => index === self.findIndex((t) =>
          t.occupantIdentifier === item.occupantIdentifier));
        nonSrArea = [];
        uniqueOccupant.forEach((occ) => {
          let areaMeetsItems;
          let totalArea = 0;
          occ.occupantLevels.forEach((level) => {
            if (level && (level?.sprinklerDesign === 'None' || level?.sprinklerDesign === null ||
              (level.sprinklerDesign === "13R" && level.sprinklerType === "Non-Creditable") ||
              (level.sprinklerType === "Shut Off"))) {
              totalArea = totalArea + level.area;
            }
          });
          if (Number(occ.combustibilityClass) == 1 || Number(occ.combustibilityClass) == 2) {
            areaMeetsItems = 'E3';
          }
          if (Number(occ.combustibilityClass) == 5) {
            areaMeetsItems = 'E4';
          }
          const object = {
            widelyScatteredArea: totalArea,
            areaDescription: occ.doingBusinessAs,
            areaMeetsItem: areaMeetsItems,
            isListedInFrf51: true
          };
          nonSrArea.push(object);
        });
      }
    }
    return nonSrArea;
  }

  getTotalOccupancyArea(control, field) {
    let val = 0;
    control?.value?.forEach((value) => {
      if (value?.widelyScatteredArea && value?.widelyScatteredArea !== '' && value?.isListedInFrf51) {
        val = val + Number(value?.widelyScatteredArea);
      }
    });
    let area = null;
    const occupants = this.formlyBuilderService.riskReport.model?.occupants;
    const sectionOccupant = occupants
      ? 'section-occupants' in occupants
        ? occupants[ 'section-occupants' ]
        : null
      : null;
    if (sectionOccupant && sectionOccupant?.totalArea) {
      area = sectionOccupant?.totalArea;
    }
    if (val !== null && val) {
      if (Number(val) > Number(area)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
}
