export enum ConfigModel {
  RiskReport = 'RiskReport',
  Order = 'Order',
}

export enum AssetType {
  Photos = 'photos',
  Documents = 'documents',
  RiskReport = 'riskreport',
  CustomerAssets = 'customerassets',
  Forms = 'forms',
  RiskReportAttachments = 'riskreportattachments',
  SprinklerReportAttachments = 'sprinklerattachment',

}

export enum SurveyOrderKeys {
  attachments = 'Attachments',
  customerAssets = 'CustomerAssets',
  photos = 'Photos',
  riskList = 'RiskList',
  billingDetails = 'BillingDetails'
}
