import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataStore, syncExpression } from '@aws-amplify/datastore';
import { Form, Group, LazyForm, Lookup, RiskReport, SurveyOrder, Target,User } from 'src/models';
import { Network } from '@ionic-native/network/ngx';
import { UtilService } from '../util-service/util.service';
import { VeriskLog } from '../util-service/verisk-log';
import { Hub, Storage as AmplifyStorage } from 'aws-amplify';
import { Filesystem, Directory, Encoding, GetUriOptions, ReadFileResult } from '@capacitor/filesystem';
import { LookupKeys } from 'src/app/models/order/lookup.model';
import { environment } from 'src/environments/environment';
import { S3SyncService } from '../s3-sync-service/s3-sync.service';
import { Storage as IonicLocalStorage } from '@ionic/storage-angular';
import { AssetType, SurveyOrderKeys } from 'src/app/enums/config-model.enum';
import { PhotoService } from '../orders/photo-service/photo.service';
import { FridService } from '../frid-service/frid.service';
import { LoadingService } from '../loading-service/loading.service';
import { Constants } from '../util-service/constants';

@Injectable({
  providedIn: 'root',
})
export class AmplifyService {
  public networkConnection: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public outBoxMutationProcessed: BehaviorSubject<any> = new BehaviorSubject(null);
  public modelSynced: BehaviorSubject<any> = new BehaviorSubject(null);
  public amplifyReady: BehaviorSubject<any> = new BehaviorSubject(null);
  public dbDeleted: boolean = false;
  private isNetworkConnection: boolean;
  //static DataStore: any;
  public amplifyReadyFlag: boolean = false;
  private templateSyncing: boolean = false;
  private lookupSyncing: boolean = false;

  constructor(
    private ngZone: NgZone,
    private network: Network,
    private utilService: UtilService,
    private ionicStorage: IonicLocalStorage,
    private photoService: PhotoService,
    private fridService: FridService,
    private loadingService: LoadingService
  ) {
    const listener = Hub.listen('datastore', async (hubData) => {
      const { event, data } = hubData.payload;

      if (event === 'modelSynced') {
        this.modelSynced.next(data);
        let analyticsLog = { name: 'amplify event modelSynced' + JSON.stringify(data) };
        this.utilService.addAnalyticsLog(analyticsLog);
      }

      if (event === 'networkStatus') {
        this.setInternetConnection(network.type && network.type !== 'none' ? true : data.active);
        console.log(`User has a network connection: ${data.active}`);
        let isNetworkConnection = data.active;
        if (!isNetworkConnection) {
          let analyticsLog = { name: 'amplify event networkStatus' + JSON.stringify(data) };
          this.utilService.addAnalyticsLog(analyticsLog);
        }
      }
      if (event === 'outboxMutationProcessed') {
        this.utilService.addTextConsoleLog('outboxMutationProcessed', JSON.stringify(event));
        this.outBoxMutationProcessed.next(data);
        console.log('amplify event ----->' + event);
        let analyticsLog = { name: 'amplify event outboxMutationProcessed' + JSON.stringify(data) };
        this.utilService.addAnalyticsLog(analyticsLog);
      }

      if (event === 'signOut') {
        //await DataStore.clear();
      }

      if (event === 'ready') {
        this.amplifyReady.next(event);
      }
    });
  }

  setInternetConnection(isActive) {
    this.ngZone.run(() => {
      this.isNetworkConnection = isActive;
      this.networkConnection.next(isActive);
    });
  }

  async DataStoreInit(loggedInFrid) {
    DataStore.configure({
      fullSyncInterval: 24 * 60,
      errorHandler: (error) => {
        let veriskLog = new VeriskLog(
          'DataStoreInit Config Error',
          'ERROR',
          this.DataStoreInit.name,
          AmplifyService.name,
          error
        );
        this.utilService.addLog(veriskLog);
      },
      syncExpressions: [
        syncExpression(SurveyOrder, (o) => o.frID.eq(loggedInFrid)), //Currently hardcoded ,in future should be login id
        syncExpression(Target, (o) => o.frID.eq(loggedInFrid)), //Currently hardcoded ,in future should be login id
        syncExpression(Group, (o) => o.frID.eq(loggedInFrid)),
        syncExpression(RiskReport, (o) => o.frID.eq(loggedInFrid)),
        syncExpression(User, (o) => o.userId.eq(loggedInFrid)),
      ],
    });
    await DataStore.start();
  }

  //TODO: This method is written for testing purpose. Can be removed in future.
  async DataStoreInitWithoutSyncExpression(){   
    DataStore.clear();
    DataStore.stop();
    DataStore.configure({
      fullSyncInterval: 24 * 60,
      errorHandler: (error) => {
        let veriskLog = new VeriskLog(
          'DataStoreInit Config Error',
          'ERROR',
          this.DataStoreInit.name,
          AmplifyService.name,
          error
        );
        this.utilService.addLog(veriskLog);
      },
      syncExpressions: [],
    });
    await DataStore.start();
  }

  get isNetWorkConnection() {
    return this.isNetworkConnection;
  }
  async dataClear() {
    await DataStore.clear();
    this.dbDeleted = true;
  }
  async datastoreReset(frId) {
    await DataStore.stop();
    await this.DataStoreInit(frId);
    this.amplifyReadyFlag = true;
  }

  public uploadJsonToS3(
    filePath: string,
    blob: Blob,
    identifier: string,
    riskReportOrFormIdentifier?: string
  ): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      AmplifyStorage.put(filePath, blob, {
        useAccelerateEndpoint: true,
        level: 'public',
        resumable: true,
        completeCallback: (_) => {
          let veriskLog = new VeriskLog(
            riskReportOrFormIdentifier
              ? `Amplify JSON Storage Success of Report/form Id: ${riskReportOrFormIdentifier} for Order Id: ${identifier}`
              : `Amplify JSON Storage Success for Report/form Id: ${identifier}`,
            'INFO',
            this.uploadJsonToS3.name,
            AmplifyService.name,
            '100% Uploaded',
            true
          );
          this.utilService.addLog(veriskLog);
          observer.next(true);
          observer.complete();
        },
        errorCallback: async (e) => {
          let veriskLog = new VeriskLog(
            'Amplify JSON Storage Failed',
            'ERROR',
            this.uploadJsonToS3.name,
            AmplifyService.name,
            e
          );
          this.utilService.addLog(veriskLog);
          if (e.name == 'ExpiredToken' || e == 'no refresh handler for provider') {
            await this.handleAmplifyStorageTokenRefresh();
          }
          observer.error(e);
        },
      });
    });
  }

  public uploadPhotoToS3(filePath: string, photo, identifier: string, photoIdentifier: string): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      AmplifyStorage.put(filePath, this.utilService.dataURItoBlob(photo.dataUrl), {
        useAccelerateEndpoint: true,
        contentType: `image/${photo.format}`,
        level: 'public',
        resumable: true,
        completeCallback: (res) => {
          let veriskLog = new VeriskLog(
            `Amplify Photo: ${photoIdentifier} Storage Success for Report Id: ${identifier}`,
            'INFO',
            this.uploadPhotoToS3.name,
            AmplifyService.name,
            '100% Uploaded',
            true
          );
          this.utilService.addLog(veriskLog);

          observer.next(true);
          observer.complete();
        },
        errorCallback: async (e) => {
          let veriskLog = new VeriskLog(
            'Amplify Photo Storage Failed',
            'ERROR',
            this.uploadPhotoToS3.name,
            AmplifyService.name,
            e
          );
          this.utilService.addLog(veriskLog);
          if (e.name == 'ExpiredToken' || e == 'no refresh handler for provider') {
            await this.handleAmplifyStorageTokenRefresh();
          }
          observer.error();
        },
      });
    });
  }
  public deleteFromS3(filePath: string): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      AmplifyStorage.remove(filePath)
        .then((result) => {
          // S3 Remove Success
          observer.next(true);
          observer.complete();
        })
        .catch((e) => {
          observer.error('Remote Delete Failed');
        });
    });
  }
  public uploadDocumentToS3(
    model: string,
    filePath: string,
    document,
    identifier: string,
    attachmentIdentifier: string
  ): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      let dataType: string;
      const fileExtension = this.utilService.getExtensionFromFileName(filePath);

      switch (fileExtension) {
        case 'pdf':
          dataType = 'pdf';
          break;
        case 'doc':
          dataType = 'msword';
          break;
        case 'docx':
          dataType = 'vnd.openxmlformats-officedocument.wordprocessingml.document';
          break;
        case 'csv':
          dataType = 'csv';
          break;
        case 'txt':
          dataType = 'text';
          break;        
      }

      AmplifyStorage.put(filePath, this.utilService.convertDocumentToBlob(document.data, dataType), {
        useAccelerateEndpoint: true,
        contentType: `application/${dataType}`,
        level: 'public',
        resumable: true,
        completeCallback: (res) => {
          let veriskLog = new VeriskLog(
            `Amplify Document: ${attachmentIdentifier} Storage Success for ${model} Id: ${identifier}`,
            'INFO',
            this.uploadDocumentToS3.name,
            AmplifyService.name,
            '100% Uploaded',
            true
          );
          this.utilService.addLog(veriskLog);

          observer.next(true);
          observer.complete();
        },
        errorCallback: async (e) => {
          let veriskLog = new VeriskLog(
            `Amplify Document: ${attachmentIdentifier} Storage Failed for ${model} Id: ${identifier}`,
            'ERROR',
            this.uploadDocumentToS3.name,
            AmplifyService.name,
            e
          );
          this.utilService.addLog(veriskLog);
          if (e.name == 'ExpiredToken' || e == 'no refresh handler for provider') {
            await this.handleAmplifyStorageTokenRefresh();
          }
          observer.error();
        },
      });
    });
  }

  public uploadHtmlToS3(filePath: string, form, identifier: string, formIdentifier: string): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      AmplifyStorage.put(filePath, new Blob([form.data as string], {type: 'text/html'}), {
        useAccelerateEndpoint: true,
        contentType: `text/html`,
        level: 'public',
        resumable: true,
        completeCallback: (res) => {
          let veriskLog = new VeriskLog(
            `Amplify form: ${formIdentifier} html Storage Success for Order Number: ${identifier}`,
            'INFO',
            this.uploadHtmlToS3.name,
            AmplifyService.name,
            '100% Uploaded',
            true
          );
          this.utilService.addLog(veriskLog);

          observer.next(true);
          observer.complete();
        },
        errorCallback: async (e) => {
          let veriskLog = new VeriskLog(
            'Saving Html Form to S3 Failed',
            'ERROR',
            this.uploadHtmlToS3.name,
            AmplifyService.name,
            e
          );
          this.utilService.addLog(veriskLog);
          if (e.name == 'ExpiredToken' || e == 'no refresh handler for provider') {
            await this.handleAmplifyStorageTokenRefresh();
          }
          observer.error();
        },
      });
    });
  }
  // The amplifystorage token is not getting refreshed sometimes even after updating token. This is a hack, and adding this makes the token refresh.
  async handleAmplifyStorageTokenRefresh() {
    let veriskLog = new VeriskLog(
      'handleAmplifyStorageTokenRefresh is being called.',
      'INFO',
      this.handleAmplifyStorageTokenRefresh.name,
      AmplifyService.name,
      null,
      true
    );
    this.utilService.addLog(veriskLog);
    const filePath = 'isurvey/riskreports/1.json';
    AmplifyStorage.get(filePath, { download: true, cacheControl: 'no-cache' }).then(
      // This is required
      (storageResult) => {
        new Response(storageResult.Body).text().then(
          (data) => {
            console.log(data);
          },
          (e) => {
            console.log(e);
            let veriskLog = new VeriskLog(
              'handleAmplifyStorageTokenRefresh Error',
              'ERROR',
              this.handleAmplifyStorageTokenRefresh.name,
              AmplifyService.name,
              e,
              true
            );
            this.utilService.addLog(veriskLog);
          }
        );
      }
    );
  }

  /**
   * Gets user form data and starts the syncing process
   * 
   * @returns {Promise<void>} a void promise
   */
  public async syncTemplateFiles(): Promise<void> {
    const timeBeforeSync = environment.templateSyncInterval;
    DataStore.observe(Form).subscribe((res) => {
      this.manageTemplateFile(res.element);
    });

    const lastSynced = await this.ionicStorage.get('lastSyncedTemplate');
    if ((!lastSynced || this.getHourDifference(lastSynced) > timeBeforeSync) && !this.templateSyncing) {
      this.templateSyncing = true;
      const forms = await DataStore.query(Form);
      forms.forEach(async (userForm: LazyForm) => {
        this.manageTemplateFile(userForm);
      });
      this.ionicStorage.set('lastSyncedTemplate', new Date());
      this.templateSyncing = false;
    }
  }

  /**
   * Creates template folder and creates file inside it if file is not already in it
   * @param userForm Form data retrieved from datastore
   * 
   * @returns {void}
   */
  public async manageTemplateFile(userForm: any): Promise<void> {
      if (userForm.templateFile?.length > 0) {
        const remotePath = `isurvey/lookup/forms/${userForm.templateFile}`;
        const filePath = `FormTemplates/${userForm.templateFile}`;
        const folder = 'FormTemplates';
        const isExistingFile = await this.checkExistingTemplateFile(folder, userForm.templateFile);

        if (!isExistingFile) {
          await this.retreiveFile(remotePath, filePath, 'json', folder);
        }
      }
  }

  /**
   * Checks if file inside the Template folder exists, returns false if the folder itself does not exist
   * @param folder folder to be queried for existing file
   * @param templateFile fiel to be checked inside folder
   * 
   * @returns {Promise<boolean>} a boolean inside a promise
   */
  public async checkExistingTemplateFile(folder: string, templateFile: string): Promise<boolean> {
    try {
      const result = await Filesystem.readdir({ path: folder, directory: Directory.Documents });
      const isExistingFile = result.files?.find(file => file.name === templateFile);
      return !!isExistingFile;
    } catch (err) {
      console.log('Folder does not exist', err)
      return false;
    }
  }

  /**
   * Gets lookup data and starts the syncing process
   * 
   * @returns {Promise<void>}
   */
  public async syncLookupFiles(): Promise<void> {
    const timeBeforeSync = environment.lookUpSyncInterval;
    const lastSynced = await this.ionicStorage.get('lastSyncedLookup');
    
    DataStore.observe(Lookup).subscribe(res => {
      this.manageLookupFile(res.element);
    });

    if ((!lastSynced || this.getHourDifference(lastSynced) > timeBeforeSync) && !this.lookupSyncing) {
      this.lookupSyncing = true;
      DataStore.query(Lookup).then((data: any[]) => {
        data?.forEach(async lookup => {
          this.manageLookupFile(lookup);
        });
      });
      this.ionicStorage.set('lastSyncedLookup', new Date());
      this.lookupSyncing = false;
    }
  }

  /**
   * Creates respective folders for storing files and retrieves file data
   * @param lookup Lookup which contains the file information
   * 
   * @returns {void}
   */
  private async manageLookupFile(lookup: any): Promise<void> {

        // Check if lookup.key includes 'svc', if it does, return early
        if (lookup.key.substring(0, 3) === 'SVC') {
          return;
      }
      const folder = lookup.key.includes('Schedule') ? 'LookUps/Schedule' : `LookUps/${lookup.key}`;
      const remotePath: string =`isurvey/${lookup.s3File}`;
      const filePath = `${folder}/${lookup.key}__${lookup._version}.json`;

      await this.manageDirectoryFiles(folder, lookup);

      await this.retreiveFile(remotePath, filePath, 'json', folder);
  }

  /**
   * Deletes the existing file if a newer version of the file is available
   * @param folder The path of the folder whose files are to be managed 
   * @param lookup existing file data against which the version and id are checked
   * 
   * @returns {Promise<void>} a void promise
   */
  private async manageDirectoryFiles(folder: string, lookup: any): Promise<void> {
    try {
      const result = await Filesystem.readdir({ path: folder, directory: Directory.Documents });
      result.files.forEach(async (file) => {
        const [fileId, fileVersion] = file.name.split('.')[0].split('__');
        if (fileId.trim() === lookup.key.trim() && +fileVersion !== lookup._version) {
          const fileConfig = {
            path: `${folder}/${file.name}`,
            directory: Directory.Documents,
          }

          await Filesystem.deleteFile(fileConfig);
        };
      });
    } catch (err) {
      console.log('Folder does not exist', err);
    }
  }

  /**
   * Calculates the difference in hours between lastSynced date time and current date time
   * @param lastSyncDate last date when the files were synced
   * 
   * @returns {number} difference in hours
   */
  private getHourDifference(lastSyncDate: Date): number {
    const currentDate = new Date();
    const hourDifference = Math.abs(lastSyncDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60);

    return hourDifference;
  }

  /**
   * Gets the data of the file provided, if file is not already stored it is fetched from remote server
   * @param serverpath Path form where remote file is fetched
   * @param filepath Path where the file will be saved locally
   * @param filetype Type of the file
   * @param folder Folder in which the file is to be stored
   * 
   * @returns {Promise<any>}
   */
  private async retreiveFile(serverpath: string, filepath: string, filetype: string, folder: string): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const clientPath: any = {
        directory: Directory.Documents,
        path: filepath
      };
      if (filetype == 'json') {
        clientPath.encoding = Encoding.UTF8;
      }

      let getUriOptions: GetUriOptions = {
        path: filepath,
        directory: Directory.Documents,
      };

      if (await this.checkFileExists(getUriOptions)) {
        Filesystem.readFile(clientPath).then(
          (file: ReadFileResult) => {
            resolve(file.data);
          },
          (e) => {
            console.log('local file missing');
            let veriskLog = new VeriskLog(
              'local file missing Error',
              'ERROR',
              this.retreiveFile.name,
              AmplifyService.name,
              e,
              true
            );
            this.utilService.addLog(veriskLog);
            
            reject(S3SyncService.LOCAL_FILE_MISSING);
          }
        );
      } else {
        try {
          // To check for existence of a file
          await this.getRemoteFile(serverpath, folder, filepath, filetype, clientPath).then((response)=>{
            resolve(response);
          },(err)=>{
            reject(err);
          })

        } catch (err) {
          console.log('cannot fetch remote file');
          let veriskLog = new VeriskLog(
            'cannot fetch remote file Error',
            'ERROR',
            this.retreiveFile.name,
            AmplifyService.name,
            err,
            true
          );
          this.utilService.addLog(veriskLog);
          reject(err);
        }
      }
    });
  }

  /**
   * Gets the remote file and saves it locally
   * @param serverpath Path form where remote file is fetched
   * @param folder Folder in which the file is to be store
   * @param filepath Path where the file will be saved locally
   * @param filetype Type of the file
   * @param clientPath The object containing the path, directory and encoding of the that is to be saved locally
   * 
   * @returns {Promise<any>}
   */
  private getRemoteFile(serverpath, folder, filepath, filetype, clientPath): Promise<any> {
    return new Promise(async (resolve, reject) => {
      AmplifyStorage.get(serverpath, { download: true, cacheControl: 'no-cache' }).then(
        (storageResult) => {
          new Response(storageResult.Body).text().then(async (data) => {
            this.checkAndCreateDir(folder).then(async (_) => {
              resolve(await this.setFile(filepath, filetype, data, clientPath, storageResult));
            });
          });
        },
        (error) => {
          if (error.name === 'NoSuchKey') {
            let veriskLog = new VeriskLog(
              `Document /${serverpath} not found in s3!`,
              'ERROR',
              this.getRemoteFile.name,
              AmplifyService.name,
              error,
              true
            );
            this.utilService.addLog(veriskLog);

            reject(`Document /${serverpath} not found in s3!`);
          } else {
            console.log('unknown error received', error);
            let veriskLog = new VeriskLog(
              `unknown error received /${error} `,
              'ERROR',
              this.getRemoteFile.name,
              AmplifyService.name,
              error,
              true
            );
            this.utilService.addLog(veriskLog);
            reject(error);
          }
        }
      );
    })
  }

  /**
   * Sets the configuration of the file to be saved locally
   * @param filepath Path where the file will be saved locally
   * @param filetype Type of the file
   * @param data The data to be inserted in the locally saved file
   * @param clientPath The object containing the path, directory and encoding of the that is to be saved locally
   * @param storageResult Result of the file fetched from the server 
   * 
   * @returns {Promise<any>}
   */
  private async setFile(filepath, filetype, data, clientPath, storageResult): Promise<any> {
    const rfo: any = {};
    rfo.directory = Directory.Documents;
    rfo.path = filepath;
    rfo.recursive = true;
    if (filetype == 'json') {
      rfo.encoding = Encoding.UTF8;
      rfo.data = data;
    } else {
      rfo.data = await this.utilService.convertBlobToBase64(storageResult.Body as Blob);
    }
     return await this.writeFile(rfo, data, clientPath)
  }

  /**
   * Creates a local file
   * @param rfo Configuration of the file
   * @param data Data tp be entered in the file
   * @param clientPath Object containng the encoding, directory and path of the file
   * @returns 
   */
  private writeFile(rfo: any, data, clientPath): Promise<any> {
    return new Promise(async (resolve, reject) => {
      Filesystem.writeFile(rfo).then(
        () => {
          resolve(data);
        },
        (e) => {
          let veriskLog = new VeriskLog(
            `syncAssetsFromRemote - LOCAL SAVE FAILED /${clientPath}   /${e.message }`,
            'ERROR',
            this.writeFile.name,
            AmplifyService.name,
            e,
            true
          );
          this.utilService.addLog(veriskLog);

          reject('syncAssetsFromRemote - LOCAL SAVE FAILED [' + clientPath + '] [' + e.message + ']');
        }
      );
    })
  }

  /**
   * Checks if a file exists
   * @param getUriOptions Object containing The path and directory of the file
   * 
   * @returns {Promise<boolean>} a boolean inside a promise
   */
  public async checkFileExists(getUriOptions: GetUriOptions): Promise<boolean> {
    try {
      await Filesystem.stat(getUriOptions);
      return true;
    } catch (checkDirException) {
      return false;
    }
  }

  /**
   * Checks if a directory exists. if directory does not exist, it creates a directory
   * @param path Path of the directory to be checked
   * 
   * @returns {Promise<boolean>} boolean inside a promise
   */
  private checkAndCreateDir(path: string): Promise<boolean> {
    return Filesystem.readdir({
      path: path,
      directory: Directory.Documents,
    })
      .then((_) => {
        return true;
      })
      .catch((_) => {
        return Filesystem.mkdir({
          path: path,
          directory: Directory.Documents,
          recursive: true,
        }).then((_) => {
          return true;
        });
      });
  }

  public async getLookupFile(lookupKey: LookupKeys) {
    const hasNetwork = this.isNetWorkConnection;
    if(hasNetwork) {
      const data = await DataStore.query(Lookup,(lkup)=>lkup.key.eq(lookupKey));
      if (data && data.length) 
        await this.manageLookupFile(data[0]);
    }  
      const folder = `LookUps/${lookupKey.includes('Schedule') ? 'Schedule' : lookupKey}`;
      const folderFiles = await Filesystem.readdir({ path: folder, directory: Directory.Documents });
      const fileVersions = [];
   
      folderFiles.files?.forEach(async (file) => {
        if(file.name.includes(lookupKey)) {
          fileVersions.push(file.name.split('__')[1].split('.')[0]);
        }
      });
      fileVersions.sort((a, b) => +b - +a);

      const localFile = await Filesystem.readFile({
        path: `${folder}/${lookupKey}__${fileVersions[0]}.json`,
        directory: Directory.Documents,
        encoding: Encoding.UTF8
      });
  
      return JSON.parse(localFile.data);
  };

  public async getTemplateFile(templateId: string) {
    const hasNetwork = this.isNetWorkConnection;
    if(hasNetwork) {
      const forms = await DataStore.query(Form);
      for(const userForm of forms) {
        const templateFile = userForm.templateFile.split('.')[0];
        if(templateFile === templateId) {
          await this.manageTemplateFile(userForm);
        }
      }
    };
    const filePath = `FormTemplates/${templateId}.json`;

    const localFile = await Filesystem.readFile({
      path: filePath,
      directory: Directory.Documents,
      encoding: Encoding.UTF8
    });

    return JSON.parse(localFile.data);
  }

  public async syncSurveyOrders(surveyOrders: any[]) {
    surveyOrders.filter(order => order.frID === this.fridService.frId).forEach(async (surveyOrder) => {
      const {orderNo} = surveyOrder;
      this.syncSurveyOrder(orderNo);
    })
  }

  public async syncOrdersByFrId(){
    try{
      
      const surveyOrders = await DataStore.query(SurveyOrder);
      console.log('surveyOrders count: '+surveyOrders.length);
      const frid = await this.fridService.frId;
      const hasDifferentFrID = surveyOrders.some(x => x.frID !== frid);
          if (hasDifferentFrID) {
            let veriskLog = new VeriskLog(
              'Orders with different frId found. For frId(s): ' + frid,
              'INFO',
              this.syncOrdersByFrId.name,
              AmplifyService.name
            );
            this.utilService.addLog(veriskLog);
            this.loadingService.showLoading(Constants.pleaseWaitMessage);
            // Reset DataStore if a different frID is found           
            await DataStore.clear();
            await DataStore.stop();
   
            await this.DataStoreInit(frid);
            this.loadingService.dismissLoading();
          }        
        }
        catch (error) {
          this.loadingService.dismissLoading();
          console.log('Error in DataStore query', error);
          let veriskLog = new VeriskLog(
            'Error while Syncing Orders By FrId',
            'ERROR',
            this.syncOrdersByFrId.name,
            AmplifyService.name,
            error
          );
          this.utilService.addLog(veriskLog);
        }
  }

  public async syncSurveyOrder(orderNo: string): Promise<any> {
    try {
        const fileData = await this.getOrderData(orderNo);
        const orderData = JSON.parse(fileData as string);
        await this.syncOrderFiles(orderNo, orderData);
        // Return a resolved Promise if everything is successful
        return Promise.resolve("Sync completed successfully");
    } catch (error) {
        // Return a rejected Promise if an error occurs
        return Promise.reject(error);
    }
  }

  public async getOrderData(orderNo: string) {
    const filepath = `${'Order'}/${orderNo}/order.json`;
    const serverpath = `isurvey/${'order'}/${orderNo}/order.json`;
    return this.retreiveFile(serverpath, filepath, 'json', `${'Order'}/${orderNo}`);
  }
  public async getRiskReportFromS3(orderNo:string,reportId:any){
    let remotepath: string = `isurvey/${'order'}/${orderNo}/${AssetType.RiskReport}/${reportId}/${reportId}.json`;
    let filepath = `${'Order'}/${orderNo}/${AssetType.RiskReport}/${reportId}/${reportId}.json`;
    let folder = `${'Order'}/${orderNo}/${AssetType.RiskReport}/${reportId}`;

    const reportFile = await this.retreiveFile(remotepath, filepath, 'json', folder);
    const parsedReportFile = JSON.parse(reportFile);

    if(parsedReportFile?.RiskReport) {
      const subFolder = 'riskreportattachments';
      this.syncRiskReportAttachments(parsedReportFile.RiskReport, orderNo, subFolder);
    }
    if(parsedReportFile?.SprinklerReport) {
      const subFolder = 'sprinklerattachment';
      this.syncRiskReportAttachments(parsedReportFile.SprinklerReport, orderNo, subFolder);
    }
    return parsedReportFile;
  }

  public async getUWFormFromS3(orderNo: string, formId: any) {
    const remotepath = `isurvey/${'order'}/${orderNo}/${AssetType.Forms}/${formId}/${formId}.json`;
    const filepath = `${'Order'}/${orderNo}/${AssetType.Forms}/${formId}/${formId}.json`;
    const folder = `${'Order'}/${orderNo}/${AssetType.Forms}/${formId}`;
    const formFile = await this.retreiveFile(remotepath, filepath, 'json', folder);
    const parsedFormFile = JSON.parse(formFile);
    return parsedFormFile;
  }

  public async getRiskReportFromLocal(orderNo: string, reportId: any) {
    const filepath = `${'Order'}/${orderNo}/${AssetType.RiskReport}/${reportId}/${reportId}.json`;
    const clientPath: any = {
      directory: Directory.Documents,
      path: filepath
    };
    clientPath.encoding = Encoding.UTF8;
    
    const reportFile = await this.readLocalFile(clientPath);
    const parsedReportFile = JSON.parse(reportFile);
    return parsedReportFile;
  }

  
  public async  syncSurveyOrderRiskReports(data, orderNo) {
    if(data[SurveyOrderKeys.riskList]?.length) {
      const promises = data[SurveyOrderKeys.riskList].map(async (file) => {
        await this.getRiskReportFromS3(orderNo, file.ReportId);
      });
      await Promise.all(promises);
    }
  }

  public async syncSurveyOrderUWForms(data, orderNo) {
    if (data[ SurveyOrderKeys.billingDetails ] !== undefined || data[ SurveyOrderKeys.billingDetails ] !== null &&
      data[ SurveyOrderKeys.billingDetails ]?.ServiceBillingInfos && data[ SurveyOrderKeys.billingDetails ]?.ServiceBillingInfos?.length) {
      for (const services of data[ SurveyOrderKeys.billingDetails ]?.ServiceBillingInfos) {
        if (services && services?.Service && services?.Service?.PackForms && services?.Service?.PackForms?.length) {
          const promises = services?.Service?.PackForms?.map(async (file) => {
            if (file?.DataFile) {
              await this.getUWFormFromS3(orderNo, file?.DataFile);
            }
          });
          await Promise.all(promises);
        }
      }
    }
  }

  
  public async syncOrderFiles(orderNo, orderData) {
    const tasks = Object.keys(orderData).map(async (key) => {
      switch (key) {
        case SurveyOrderKeys.attachments:
          await this.syncOrderAttachments(orderData, orderNo);
          break;
        case SurveyOrderKeys.customerAssets:
          await this.syncSurveyOrderCustomerAssets(orderData, orderNo);
          break;
        case SurveyOrderKeys.photos:
          await this.syncSurveyOrderPhotos(orderData, orderNo);
          break;
        case SurveyOrderKeys.riskList:
          await this.syncSurveyOrderRiskReports(orderData, orderNo);
          break;
        case SurveyOrderKeys.billingDetails:
          await this.syncSurveyOrderUWForms(orderData, orderNo);
          break;
        default:
          break;
      }
    });
    await Promise.all(tasks);
  }


  public async syncOrderAttachments(data, orderNo): Promise<void> {
    if(data[SurveyOrderKeys.attachments]?.length) {
      (data[SurveyOrderKeys.attachments]).forEach(async (file) => {
        let attachId = file.UniqueKey;
        let fileExt = file.FileName.substr(file.FileName.lastIndexOf('.'));
        let remotepath: string = `isurvey/${'order'}/${orderNo}/${'documents'}/${attachId}${fileExt}`;
        let filepath = `${'Order'}/${orderNo}/${'documents'}/${attachId}${fileExt}`;
        let folder = `${'Order'}/${orderNo}/${'documents'}`;
        await this.downloadFileFromS3(remotepath, filepath, '', folder)
      });
    }
  }

  public async syncSurveyOrderCustomerAssets(data, orderNo) {
    if(data[SurveyOrderKeys.customerAssets]?.length) {
      data[SurveyOrderKeys.customerAssets].forEach(async (pdfFile) => {
        let remotepath: string = `isurvey/${'order'}/${orderNo}/${AssetType.CustomerAssets}/${pdfFile.FileName}`;
        let filepath = `${'Order'}/${orderNo}/${AssetType.CustomerAssets}/${pdfFile.FileName}`;
        let folder = `${'Order'}/${orderNo}/${AssetType.CustomerAssets}`;

        await this.downloadFileFromS3(remotepath, filepath, '', folder)
      })
    }
    
  }

  public async syncSurveyOrderPhotos(data, orderNo) {
    if(data[SurveyOrderKeys.photos]?.length) {
      data[SurveyOrderKeys.photos].forEach(async (photo) => {
        let remotepath: string = `isurvey/${'order'}/${orderNo}/${AssetType.Photos}/${photo.Id}.jpeg`;
        let filepath = `${'Order'}/${orderNo}/${AssetType.Photos}/${photo.Id}.jpeg`;
        let folder = `${'Order'}/${orderNo}/${AssetType.Photos}`;

        await this.downloadFileFromS3(remotepath, filepath, '', folder).then(()=>{},
        (err) => {
          //Issue: when multiple photos are added from photo gallery s3 sync fails for one of the image
          //the order json will have a reference to the deleted file which causes sync error
          //this is a work around if such a situation arises then delete that file from order json and order queue
          this.photoService.delete_local_photos(photo.Id);
        }
        )
      })
    }
    }
  public syncRiskReportAttachments(riskReport, orderNo, subFolderName) {
    const reportAttachments = riskReport.ReportAttachments;
    if (reportAttachments?.length) {
      reportAttachments.forEach(async (attachment) => {
        let reportId: string = '';
        let folder: string = '';
        let filepath: string = '';
        let remotepath: string = '';
        if (subFolderName == 'sprinklerattachment') {
          reportId = riskReport.ReportId;
          folder = `${'Order'}/${orderNo}/${AssetType.RiskReport}/${reportId}/${subFolderName}`;
          filepath = `${folder}/${attachment.FileName + attachment.FileType}`;
          remotepath = `isurvey/${'order'}/${orderNo}/${AssetType.RiskReport}/${reportId}/${subFolderName}/${
            attachment.FileName + attachment.FileType
          }`;
        } else {
          reportId = riskReport.ReportIdentifier;
          folder = `${'Order'}/${orderNo}/${AssetType.RiskReport}/${reportId}/${subFolderName}`;
          filepath = `${folder}/${attachment.FileName}`;
          remotepath = `isurvey/${'order'}/${orderNo}/${AssetType.RiskReport}/${reportId}/${subFolderName}/${
            attachment.FileName
          }`;
        }

        await this.downloadFileFromS3(remotepath, filepath, '', folder);
      });
    }
  }

  private async downloadFileFromS3(serverpath: string, filepath: string, filetype: string, folder: string): Promise<any> {
    const clientPath: any = {
      directory: Directory.Documents,
      path: filepath
    };

    let getUriOptions: GetUriOptions = {
      path: filepath,
      directory: Directory.Documents,
    };

    if (!await this.checkFileExists(getUriOptions)) {
      try {
        AmplifyStorage.get(serverpath, { download: true, cacheControl: 'no-cache' }).then(
          (storageResult) => {
            new Response(storageResult.Body).text().then(async (data) => {
              this.checkAndCreateDir(folder).then(async (_) => {
                await this.setAttachment(filepath, filetype, data, clientPath, storageResult)
              });
            });
          },
          (error) => {
            if (error.name === 'NoSuchKey') {
              let veriskLog = new VeriskLog(
                `Document /${serverpath} not found in s3!`,
                'ERROR',
                this.downloadFileFromS3.name,
                AmplifyService.name,
                error,
                true
              );
              this.utilService.addLog(veriskLog);
            } else {
              console.log('unknown error received', error);
              let veriskLog = new VeriskLog(
                `unknown error received /${error} `,
                'ERROR',
                this.downloadFileFromS3.name,
                AmplifyService.name,
                error,
                true
              );
              this.utilService.addLog(veriskLog);
            }
          }
        );

      } catch (err) {
        console.log('cannot fetch remote file');
        let veriskLog = new VeriskLog(
          'cannot fetch remote attachment Error',
          'ERROR',
          this.downloadFileFromS3.name,
          AmplifyService.name,
          err,
          true
        );
        this.utilService.addLog(veriskLog);
      }
    }
}

private async setAttachment(filepath, filetype, data, clientPath, storageResult): Promise<any> {
  const rfo: any = {};
  rfo.directory = Directory.Documents;
  rfo.path = filepath;
  rfo.recursive = true;
  rfo.data = await this.utilService.convertBlobToBase64(storageResult.Body as Blob);
   
  await this.writeFile(rfo, data, clientPath);
  data = null;
}

private async readLocalFile(clientPath): Promise<any>{
  return new Promise(async (resolve, reject) => {
  Filesystem.readFile(clientPath).then(
    (file: ReadFileResult) => {
      resolve(file.data);
    },
    (e) => {
      console.log('local file missing');
      let veriskLog = new VeriskLog(
        'local file missing Error',
        'ERROR',
        this.retreiveFile.name,
        AmplifyService.name,
        e,
        true
      );
      this.utilService.addLog(veriskLog);
      
      reject(S3SyncService.LOCAL_FILE_MISSING);
    }
  );
  });
}

}
