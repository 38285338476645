import { FormControl } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';

export class NonSpinkleredAreasSectionModel extends FormlySectionModelAbstract {
  protected ngUnsubscribe1 = new Subject<void>();
  constructor(private formlyBuilderService: FormlyBuilderService) {
    super({      
      // Commented for now since data model for this model is not ready because it is causing issue in resetmodel
      key: 'asgrNonSprinkleredObstructedArea',
      type: 'rf-sheet-section',
      props: {
        label: 'Non - Sprinklered Areas',
        subTitle: 'Non - Sprinklered Areas',
        title: 'Sprinkler Report',
        isCollapsable: true,
        isCollapsed: false,
        isDisableable: true,
        isApplied : false,
        actionButtons: [
          {
            section: 'nonSprinkleredAreas',
            action: 'showFormlySheet',
            label: 'Add',
            isDisabled: false,
            service: 'formlyWrapperService',
          },
        ],
        sheetButtons: [
          {
            section: 'nonSprinkleredAreas',
            action: 'onClose',
            label: 'Close',
            service: 'nonSprinkleredAreaService',
            className: 'close-btn medium-btn',
            passInitialModel: true,
          },
          {
            section: 'nonSprinkleredAreas',
            action: 'onApply',
            label: 'Apply',
            service: 'nonSprinkleredAreaService',
            className: 'apply-btn large-btn',
            hasConfirmationPopup: true,
          },
        ],
      },
      expressions: {
        hide: (field: FormlyFieldConfig) => field.form?.root?.get('evidenceOfFireSprinkler')?.value === true ||
          field.form?.root?.get('evidenceOfFireSprinkler')?.value === 'true' ? false : true,
        //'props.actionButtons[0].label': (field: FormlyFieldConfig) => {
          // Check if model hasValues
         //  const formlyBuilder: FormlyBuilderService = field?.options?.formState?.service;
        //   const hasValues = formlyBuilder.hasValues(field?.formControl?.value,
        //     true,
        //     [
        //       "keyOfPropertyWithDefaultTrueValue"
        //     ]);
        //   return hasValues ? 'Edit' : 'Add';
        // }        
      },
      hooks:{
        onInit: (field: FormlyFieldConfig) =>{          
          let exclusions:string[] = ['reportId', 
                                    'isUnusedBeneathCombustibleLowerFloor',
                                    'isUnusedNonCombustibleFloorRoofSpace',
                                    'isUnusedCombustibleRoofSpaceAboveConcrete'];
          //Checking Add or Edit Button to Display based on hasValue and Existing Risk
          if(formlyBuilderService.hasValues(field.model,false,exclusions)
            ||  formlyBuilderService.isExistingRisk()){            
            field.props.actionButtons[0].label = 'Edit'
            field.formControl.markAllAsTouched();
          }
          //Subscribing to Apply Button Click and Save event to S3.
          formlyBuilderService.nonSprinkleredAreaService.SaveToS3Event.pipe(takeUntil(this.ngUnsubscribe1),
          //debounceTime(Constants.riskFormDataSyncDelay),
          distinctUntilChanged())
          .subscribe(()=>{
            if(field?.props?.isApplied){
              field.props.actionButtons[0].label = 'Edit'
              field.formControl.markAllAsTouched();
            }
          }); 
          field.form.get('asgrNonSprinkleredObstructedArea.usedNonSprinkleredObstructedAreas').updateValueAndValidity();       
        },
        onDestroy: () => {
          this.ngUnsubscribe1.next();
          this.ngUnsubscribe1.complete();
        }
      },
      fieldGroup: [
        {
          fieldGroup: [
            {
              // Commented for now since data model for this model is not ready because it is causing issue in resetmodel
              className: 'unused-area-container',
              key: 'unusedNonSprinkleredAreas',
              wrappers: [ 'simple-section-wrapper' ],
              props: {
                label: 'Unused Non-Sprinklered Areas',
                isCollapsable: true,
                isCollapsed: false,
                className: 'padding-bottom',
                shallowErros:false,
              },
              fieldGroup: [
                {
                  fieldGroupClassName: 'formly-flex-container',
                  fieldGroup: [
                    {
                      key: 'reportId',
                    },
                    {
                      className: 'row-label',
                      template: '<label></label>',
                    },
                    {
                      template: '<label>Required</label>',
                      className: 'first-row',
                    },
                    {
                      template: '<label>Affected</label>',
                      className: 'first-row',
                    },
                    {
                      template: '<span></span>',
                      className: 'new-row',
                    },
                    {
                      className: 'row-label first-label',
                      template: '<label>Beneath the Combustible Lowest Floor</label>',
                    },
                    {
                      type: 'custom-checkbox',
                      key: 'isUnusedBeneathCombustibleLowerFloor',
                      defaultValue: false,
                      className: 'first-checkbox',
                      props: {
                        isCardEnabled: false,
                      },
                      hooks: {
                        onInit: (field: FormlyFieldConfig) => {
                          field?.formControl?.valueChanges.subscribe((value) => {
                            if (value !== null && value) {
                              field?.form?.markAllAsTouched();
                            }
                          });
                        },
                      },
                    },
                    {
                      key: 'requiredAreaUnusedBeneathCombustibleLowerFloor',
                      type: 'simple-input',
                      className: 'first-col-input area-input-with-label',
                      defaultValue: null,
                      props: {
                        unit: 'sq.ft.',
                        type: 'number',
                        isAsteriskNotRequired: true,
                      },
                      expressions: {
                        'props.readonly': (field: FormlyFieldConfig) => {
                          const isChecked = field?.form?.get('isUnusedBeneathCombustibleLowerFloor')?.value;
                          if (!isChecked) {
                            field.form?.get('requiredAreaUnusedBeneathCombustibleLowerFloor')?.reset();
                          }
                          return !isChecked;
                        },
                        'props.required': (field: FormlyFieldConfig) =>
                          field?.form?.get('isUnusedBeneathCombustibleLowerFloor')?.value,
                      },
                      hooks: {
                        onInit: (field: FormlyFieldConfig) => {
                          field?.formControl?.markAllAsTouched();
                          field?.formControl?.valueChanges.subscribe((value) => {
                            field?.form
                              ?.get('affectedAreaUnusedBeneathCombustibleLowerFloor')
                              ?.updateValueAndValidity();
                          });
                        },
                      },
                      validators: {
                        spocValidation: {
                          expression: (control: FormControl, field: FormlyFieldConfig) =>
                            formlyBuilderService.nonSprinkleredAreaService.requiredAreaSpocValidation(control, field),
                          message: 'Area cannot exceed building area',
                        },
                      },
                    },

                    {
                      key: 'affectedAreaUnusedBeneathCombustibleLowerFloor',
                      type: 'simple-input',
                      className: 'first-col-input area-input-with-label',
                      defaultValue: null,
                      props: {
                        unit: 'sq.ft.',
                        //label: 'Affected',
                        type: 'number',
                        isAsteriskNotRequired: true,
                      },
                      expressions: {
                        'props.readonly': (field: FormlyFieldConfig) => {
                          const isChecked = field?.form?.get('isUnusedBeneathCombustibleLowerFloor')?.value;
                          if (!isChecked) {
                            field?.form?.get('affectedAreaUnusedBeneathCombustibleLowerFloor')?.reset();
                          }
                          return !isChecked;
                        },
                        'props.required': (field: FormlyFieldConfig) =>
                          field?.form?.get('isUnusedBeneathCombustibleLowerFloor')?.value,
                      },
                      hooks: {
                        onInit: (field: FormlyFieldConfig) => {
                          field?.formControl?.markAllAsTouched();
                        },
                      },
                      validators: {
                        affectedAreaValidator: {
                          expression: (control: FormControl, field: FormlyFieldConfig) =>
                            formlyBuilderService.nonSprinkleredAreaService.affectedAreaValidation(
                              control,
                              field,
                              'requiredAreaUnusedBeneathCombustibleLowerFloor'
                            ),
                          message: 'Affected area cannot be larger than required area',
                        },
                      },
                    },
                    {
                      template: '<span></span>',
                      className: 'new-row',
                    },
                    {
                      className: 'row-label',
                      template: '<label>In Combustible Concealed Attic</label>',
                    },
                    {
                      type: 'custom-checkbox',
                      key: 'isUnusedCombustibleConcealedCeilingAttic',
                      defaultValue: null,
                      className: 'first-checkbox',
                      hooks: {
                        onInit: (field: FormlyFieldConfig) => {
                          const isCombustible =
                            formlyBuilderService.nonSprinkleredAreaService.checkCombustibleConcealedArea(field);
                          if (isCombustible) {
                            field?.formControl?.setValue(true);
                          }
                          field?.formControl?.valueChanges.subscribe((value) => {
                            if (value !== null && value) {
                              field?.form?.markAllAsTouched();
                            }
                          });
                        },
                      },
                    },
                    {
                      key: 'requiredAreaUnusedCombustibleConcealedCeilingAttic',
                      type: 'simple-input',
                      className: 'area-input-with-label',
                      defaultValue: null,
                      props: {
                        unit: 'sq.ft.',
                        type: 'number',
                        isAsteriskNotRequired: true,
                      },
                      expressions: {
                        'props.readonly': (field: FormlyFieldConfig) => {
                          const isChecked = field?.form?.get('isUnusedCombustibleConcealedCeilingAttic')?.value;
                          if (!isChecked) {
                            field?.form?.get('requiredAreaUnusedCombustibleConcealedCeilingAttic')?.reset();
                          }
                          return !isChecked;
                        },
                        'props.required': (field: FormlyFieldConfig) =>
                          field?.form?.get('isUnusedCombustibleConcealedCeilingAttic')?.value,
                      },
                      hooks: {
                        onInit: (field: FormlyFieldConfig) => {
                          field?.formControl?.markAllAsTouched();
                          field?.formControl?.valueChanges.subscribe((value) => {
                            field?.form
                              ?.get('affectedAreaUnusedCombustibleConcealedCeilingAttic')
                              ?.updateValueAndValidity();
                          });
                        },
                      },
                      validators: {
                        roofAreaValidator: {
                          expression: (control: FormControl, field: FormlyFieldConfig) =>
                            formlyBuilderService.nonSprinkleredAreaService.checkRoofAreaValidation(control, field),
                          message: 'Area cannot exceed roof area',
                        },
                      },
                    },

                    {
                      key: 'affectedAreaUnusedCombustibleConcealedCeilingAttic',
                      type: 'simple-input',
                      className: 'area-input-with-label',
                      defaultValue: null,
                      props: {
                        unit: 'sq.ft.',
                        type: 'number',
                        isAsteriskNotRequired: true,
                      },
                      expressions: {
                        'props.readonly': (field: FormlyFieldConfig) => {
                          const isChecked = field?.form?.get('isUnusedCombustibleConcealedCeilingAttic')?.value;
                          if (!isChecked) {
                            field?.form?.get('affectedAreaUnusedCombustibleConcealedCeilingAttic')?.reset();
                          }
                          return !isChecked;
                        },
                        'props.required': (field: FormlyFieldConfig) =>
                          field.form?.get('isUnusedCombustibleConcealedCeilingAttic')?.value,
                      },
                      hooks: {
                        onInit: (field: FormlyFieldConfig) => {
                          field?.formControl?.markAllAsTouched();
                          const isCombustible =
                            formlyBuilderService.nonSprinkleredAreaService.checkCombustibleConcealedArea(field);
                          if (
                            isCombustible &&
                            field?.model?.affectedAreaUnusedCombustibleConcealedCeilingAttic === null
                          ) {
                            field?.formControl?.setValue(isCombustible);
                          }
                        },
                      },
                      validators: {
                        affectedAreaValidator: {
                          expression: (control: FormControl, field: FormlyFieldConfig) =>
                            formlyBuilderService.nonSprinkleredAreaService.affectedAreaValidation(
                              control,
                              field,
                              'requiredAreaUnusedCombustibleConcealedCeilingAttic'
                            ),
                          message: 'Affected area cannot be larger than required area',
                        },
                      },
                    },
                    {
                      template: '<span></span>',
                      className: 'new-row',
                    },

                    {
                      className: 'row-label',
                      template: '<label>In Combustible Roof Space Above Concrete</label>',
                    },
                    {
                      type: 'custom-checkbox',
                      key: 'isUnusedCombustibleRoofSpaceAboveConcrete',
                      defaultValue: false,
                      className: 'first-checkbox',
                      expressions: {
                        'props.isDisabled': (field: FormlyFieldConfig) =>
                          !formlyBuilderService.nonSprinkleredAreaService.checkRoofContructionTypeAndDeck(field),
                      },
                      hooks: {
                        onInit: (field: FormlyFieldConfig) => {
                          field?.formControl?.valueChanges.subscribe((value) => {
                            if (value !== null && value) {
                              field?.form?.markAllAsTouched();
                            }
                          });
                        },
                      },
                    },
                    {
                      key: 'requiredAreaUnusedCombustibleRoofSpaceAboveConcrete',
                      type: 'simple-input',
                      className: 'area-input-with-label',
                      defaultValue: null,
                      props: {
                        unit: 'sq.ft.',
                        type: 'number',
                        isAsteriskNotRequired: true,
                      },
                      expressions: {
                        'props.readonly': (field: FormlyFieldConfig) => {
                          const isChecked = field?.form?.get('isUnusedCombustibleRoofSpaceAboveConcrete')?.value;
                          if (!isChecked) {
                            field?.form?.get('requiredAreaUnusedCombustibleRoofSpaceAboveConcrete')?.reset();
                          }
                          return !isChecked;
                        },
                        'props.required': (field: FormlyFieldConfig) =>
                          field.form?.get('isUnusedCombustibleRoofSpaceAboveConcrete')?.value,
                      },
                      hooks: {
                        onInit: (field: FormlyFieldConfig) => {
                          field?.formControl?.markAllAsTouched();
                          field?.formControl?.valueChanges.subscribe((value) => {
                            field?.form
                              ?.get('affectedAreaUnusedCombustibleRoofSpaceAboveConcrete')
                              ?.updateValueAndValidity();
                          });
                        },
                      },
                      validators: {
                        roofAreaValidator: {
                          expression: (control: FormControl, field: FormlyFieldConfig) =>
                            formlyBuilderService.nonSprinkleredAreaService.checkRoofAreaValidation(control, field),
                          message: 'Area cannot exceed roof area',
                        },
                      },
                    },

                    {
                      key: 'affectedAreaUnusedCombustibleRoofSpaceAboveConcrete',
                      type: 'simple-input',
                      className: 'area-input-with-label',
                      defaultValue: null,
                      props: {
                        unit: 'sq.ft.',
                        type: 'number',
                        isAsteriskNotRequired: true,
                      },
                      expressions: {
                        'props.readonly': (field: FormlyFieldConfig) => {
                          const isChecked = field?.form?.get('isUnusedCombustibleRoofSpaceAboveConcrete')?.value;
                          if (!isChecked) {
                            field.form?.get('affectedAreaUnusedCombustibleRoofSpaceAboveConcrete')?.reset();
                          }
                          return !isChecked;
                        },
                        'props.required': (field: FormlyFieldConfig) =>
                          field?.form?.get('isUnusedCombustibleRoofSpaceAboveConcrete')?.value,
                      },
                      hooks: {
                        onInit: (field: FormlyFieldConfig) => {
                          field?.formControl?.markAllAsTouched();
                        },
                      },
                      validators: {
                        affectedAreaValidator: {
                          expression: (control: FormControl, field: FormlyFieldConfig) =>
                            formlyBuilderService.nonSprinkleredAreaService.affectedAreaValidation(
                              control,
                              field,
                              'requiredAreaUnusedCombustibleRoofSpaceAboveConcrete'
                            ),
                          message: 'Affected area cannot be larger than required area',
                        },
                      },
                    },
                    {
                      template: '<span></span>',
                      className: 'new-row',
                    },
                    {
                      className: 'row-label',
                      template: '<label>Which is Non - Combustible Floor/Roof Space</label>',
                    },
                    {
                      type: 'custom-checkbox',
                      key: 'isUnusedNonCombustibleFloorRoofSpace',
                      defaultValue: false,
                      className: 'first-checkbox',
                      hooks: {
                        onInit: (field: FormlyFieldConfig) => {
                          field?.formControl?.valueChanges.subscribe((value) => {
                            if (value !== null && value) {
                              field?.form?.markAllAsTouched();
                            }
                          });
                        },
                      },
                    },
                    {
                      key: 'requiredAreaUnusedNonCombustibleFloorRoofSpace',
                      type: 'simple-input',
                      className: 'area-input-with-label',
                      defaultValue: null,
                      props: {
                        unit: 'sq.ft.',
                        type: 'number',
                        isAsteriskNotRequired: true,
                      },
                      expressions: {
                        'props.readonly': (field: FormlyFieldConfig) => {
                          const isChecked = field?.form?.get('isUnusedNonCombustibleFloorRoofSpace')?.value;
                          if (!isChecked) {
                            field?.form?.get('requiredAreaUnusedNonCombustibleFloorRoofSpace')?.reset();
                          }
                          return !isChecked;
                        },
                        'props.required': (field: FormlyFieldConfig) =>
                          field?.form?.get('isUnusedNonCombustibleFloorRoofSpace')?.value,
                      },
                      hooks: {
                        onInit: (field: FormlyFieldConfig) => {
                          field?.formControl?.markAllAsTouched();
                          field?.formControl?.valueChanges.subscribe((value) => {
                            field?.form
                              ?.get('affectedAreaUnusedNonCombustibleFloorRoofSpace')
                              ?.updateValueAndValidity();
                          });
                        },
                      },
                      validators: {
                        spocValidation: {
                          expression: (control: FormControl, field: FormlyFieldConfig) =>
                            formlyBuilderService.nonSprinkleredAreaService.requiredAreaSpocValidation(control, field),
                          message: 'Area cannot exceed building area',
                        },
                      },
                    },

                    {
                      key: 'affectedAreaUnusedNonCombustibleFloorRoofSpace',
                      type: 'simple-input',
                      className: 'area-input-with-label',
                      defaultValue: null,
                      props: {
                        unit: 'sq.ft.',
                        type: 'number',
                        isAsteriskNotRequired: true,
                      },
                      expressions: {
                        'props.readonly': (field: FormlyFieldConfig) => {
                          const isChecked = field.form?.get('isUnusedNonCombustibleFloorRoofSpace')?.value;
                          if (!isChecked) {
                            field?.form?.get('affectedAreaUnusedNonCombustibleFloorRoofSpace')?.reset();
                          }
                          return !isChecked;
                        },
                        'props.required': (field: FormlyFieldConfig) =>
                          field?.form?.get('isUnusedNonCombustibleFloorRoofSpace')?.value,
                      },
                      hooks: {
                        onInit: (field: FormlyFieldConfig) => {
                          field?.formControl?.markAllAsTouched();
                        },
                      },
                      validators: {
                        affectedAreaValidator: {
                          expression: (control: FormControl, field: FormlyFieldConfig) =>
                            formlyBuilderService.nonSprinkleredAreaService.affectedAreaValidation(
                              control,
                              field,
                              'requiredAreaUnusedNonCombustibleFloorRoofSpace'
                            ),
                          message: 'Affected area cannot be larger than required area',
                        },
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          wrappers: ['simple-section-wrapper','import-icon-wrapper'],
          type: 'sr-field-array',
          key: 'usedNonSprinkleredObstructedAreas',
          className: 'used-non-sprinklered-container',
          props: {
            showAddButton: true,
            label: 'Used Non - Sprinklered / Obstructed Areas',
            isGridHeaderDisabled: true,
            showError: true,
            shallowErrors: true,
            checkTouchedControl: true,
            commentFieldKey:'areaComments',
            swipeActionDetails: [
              { action: 'clone', styleName: 'cloneIcon' },
              //{ action: 'comment', styleName: 'commentIcon' },
            ],
            importActions:[
             {
             section: 'nonSprinkleredAreas',
             action: 'importNonSprinkleredArea',
             label: 'Add',
             isDisabled: false,
             service: 'nonSprinkleredAreaService',
            }
            ]
          },
          expressions:{
            'props.isImportHidden':(field: FormlyFieldConfig)=>{
               if(field?.fieldGroup?.length > 0){
                return true;
               }else{
                return false;
               }
            }
          },
          fieldArray: {
            fieldGroupClassName: 'formly-flex-container',
            wrappers: ['error-info-wrapper'],
            fieldGroup: [
              {
                key: 'widelyScatteredArea',
                type: 'simple-input',
                className: 'area-input area-input-with-label',
                defaultValue: null,
                props: {
                  label: 'Area',
                  unit: 'sq.ft.',
                  type: 'number',
                  isAsteriskNotRequired: true,
                  required:true
                },
              },
              {
                key: 'reportId',
                defaultValue: null,
                hooks:{
                  onInit:(field: FormlyFieldConfig)=>{
                     formlyBuilderService.nonSprinkleredAreaService.setReportId(field);
                  }
                }
              },
              {
                key: 'areaComments',
                defaultValue:null,
                props: {
                  commentActionKey: 'areaComments',
                },
              },
              {
                key: 'usedNonSprinkleredObstructedAreaId',
                defaultValue: null,
                hooks: {
                  onInit: (field: FormlyFieldConfig) => {
                    formlyBuilderService.nonSprinkleredAreaService.generateObstructedAreaId(field);
                  },
                },
              },
              {
                key: 'isListedInFrf51',
                defaultValue: null,
              },
              {
                key: 'areaDescription',
                type: 'simple-input',
                className: 'description-input',
                defaultValue: null,
                props: {
                  label: 'Description',
                  isAsteriskNotRequired: true,
                  required:true,
                  maxLength: 200
                },
              },
              {
                template: '<span></span>',
                className: 'new-row',
              },
              {
                key: 'areaMeetsItem',
                type: 'simple-select',
                className: 'area-modifier-select',
                defaultValue: null,
                props: {
                  label: 'Area Modifier',
                  isAsteriskNotRequired: true,
                  required:true,
                  options: formlyBuilderService.nonSprinkleredAreaService.getAreaModifierOptions()
                },
                hooks:{
                  onInit:(field: FormlyFieldConfig)=>{
                        field?.formControl?.valueChanges.subscribe((val)=>{
                            if(val){
                              if(val === 'E2'|| val ==='E5'){
                                field?.form?.get('isListedInFrf51')?.setValue(false);
                              }else{
                                field?.form?.get('isListedInFrf51')?.setValue(true);
                              }
                            }
                        });
                  }
                }
              },
              {
                type: 'custom-checkbox',
                key: 'isWidelyScattered',
                defaultValue: false,
                className: 'first-checkbox',
                props: {
                  label: 'Widely Scattered',
                },
              },
            ],
          },
          validators:{
            areaValidators:{
              expression:(control: FormControl, field: FormlyFieldConfig)=>
              formlyBuilderService.nonSprinkleredAreaService.getTotalOccupancyArea(control,field),
              message:'NS Areas cannot exceed building area'
            },
            // validation: [
            //   { name: 'non-sprinklered-area-validator', options: { sectionName: 'non-sprinklered-area' }}
            // ]
          }
        },
      ],
    });
  }
}
