import { FormlySectionModelAbstract } from 'src/app/abstract/formly-builder/formly-section.abstract';
import { FormlyBuilderService } from 'src/app/services/formly-builder/formly-builder.service';
import { MainDrainTestingSectionModel } from './main-drain-testing.model';
import { DryPipeValveTestingSectionModel } from './dry-pipe-valve-testing.model';
import { OverAgeSprinklerTestSectionModel } from './overage-sprinkler-test.model';
import { AlarmSupervisoryTestSectionModel } from './alarm-supervisory-test.model';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FirePumpTestingSectionModel } from './fire-pump-testing.model';
import { isEqual } from 'lodash';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

export class SystemTestingSectionModel extends FormlySectionModelAbstract {
  protected ngUnsubscribe1 = new Subject<void>();
  constructor(private formlyBuilderService: FormlyBuilderService) {
    super({
      key: 'asgrSprinklerSystemTest',
      type: 'rf-sheet-section',
      props: {
        label: 'System Testing',
        subTitle: 'System Testing',
        title: 'Sprinkler Report',
        isCollapsable: true,
        isCollapsed: false,
        isDisableable: true,
        isApplied: false,
        actionButtons: [
          {
            section: 'systemTesting',
            action: 'showFormlySheet',
            label: 'Add',
            isDisabled: false,
            service: 'formlyWrapperService',
          },
        ],
        sheetButtons: [
          {
            section: 'systemTesting',
            action: 'onClose',
            label: 'Close',
            service: 'systemTestingService',
            className: 'close-btn medium-btn',
            passInitialModel: true,
          },
          {
            section: 'systemTesting',
            action: 'onApply',
            label: 'Apply',
            service: 'systemTestingService',
            className: 'apply-btn large-btn',
            hasConfirmationPopup: true,
          },
        ],
      },
      expressions: {
        hide: (field: FormlyFieldConfig) => field.form?.root?.get('evidenceOfFireSprinkler')?.value === true ||
          field.form?.root?.get('evidenceOfFireSprinkler')?.value === 'true' ? false : true,
        // 'props.actionButtons[0].label': (field: FormlyFieldConfig) => {
        //   // Check if model hasValues
        //   const formlyBuilder: FormlyBuilderService = field?.options?.formState?.service;
        //   const hasValues = formlyBuilder.hasValues(field?.formControl?.value, false);
        //   return hasValues ? 'Edit' : 'Add';
        // }
      },
      hooks:{
        onInit: (field: FormlyFieldConfig) =>{          
          let exclusions:string[] = ['reportId',
                                      'isFirePumpTestNotApplicable',
                                      'hasFirePumpTest'];
          //Checking Add or Edit Button to Display based on hasValue and Existing Risk
          if(formlyBuilderService.hasValues(field.model,false,exclusions) || formlyBuilderService.isExistingRisk()){            
            field.props.actionButtons[0].label = 'Edit'
            field.formControl.markAllAsTouched();
          }
          //Subscribing to Apply Button Click and Save event to S3.
          formlyBuilderService.systemTestingService.SaveToS3Event.pipe(takeUntil(this.ngUnsubscribe1),
          //debounceTime(Constants.riskFormDataSyncDelay),
          distinctUntilChanged())
          .subscribe(()=>{
            if(field?.props?.isApplied){
              field.props.actionButtons[0].label = 'Edit'
              field.formControl.markAllAsTouched();
            }
          });        
          field?.form?.get('asgrSprinklerSystemTest.mainDrainTesting.mainDrainTestDate')?.updateValueAndValidity();
        },
        onDestroy: () => {
          this.ngUnsubscribe1.next();
          this.ngUnsubscribe1.complete();
        }
      },
      fieldGroup: [
        {
          key: 'systemTesting',
          wrappers: [ 'simple-section-wrapper' ],
          props: {
            label: 'System Testing',
            isCollapsable: true,
            isCollapsed: false,
            className: 'padding-bottom'
          },
          className: 'system-testing-container',
          fieldGroup: [
            {
              key: 'hasScheduledAppliedDate',
              type: 'custom-checkbox',
              props: {
                label: 'Schedule Applied Date',
                isCardEnabled: true,
                labelFirst: true,
                rightAlign: true,
              },
            },
            {
              key: 'hasNoAsCredit',
              type: 'custom-checkbox',
              props: {
                label: 'No automatic Sprinklered Credit, Periodic testing',
                isCardEnabled: true,
                labelFirst: true,
                rightAlign: true,
              },
            },
            {
              key: 'hasNoRateRecognition',
              type: 'custom-checkbox',
              props: {
                label: 'No automatic Sprinklered Credit, Initial testing',
                isCardEnabled: true,
                labelFirst: true,
                rightAlign: true,
              },
            },
          ]
        },
        MainDrainTestingSectionModel.build(formlyBuilderService).fields,
        DryPipeValveTestingSectionModel.build(formlyBuilderService).fields,
        FirePumpTestingSectionModel.build(formlyBuilderService).fields,
        {
          key: 'isFirePumpTestNotApplicable',
          defaultValue: true
        },
        {
          key: 'acceptanceTesting',
          wrappers: [ 'simple-section-wrapper' ],
          className: 'acceptance-test-container',
          props: {
            label: 'Acceptance Testing',
            isCollapsable: true,
            isCollapsed: false,
            className: 'padding-bottom',
            showError: true,
            checkTouchedControl: true
          },
          //Acceptance Testing Toggle
          fieldGroup: [
            {
              wrappers: [ 'simple-section-wrapper' ],
              className:'acceptance-test',
              props: {
                label: 'Flushing and Hydrostatic',
                isCollapsable: false,
                isCollapsed: false,
                className: 'padding-bottom',
                showError: false
              },
              fieldGroup: [
                {
                  key: 'hasFlushingAndHydrostaticTests',
                  type: 'rf-yes-no',
                  defaultValue: null,
                  wrappers: [ 'question-wrapper' ],
                  className: 'flushing-hydrostatic-section',
                  props: {
                    label: 'Flushing and Hydrostatic Tests',
                    required: true,
                    isFontSizeSmall: true,
                    isAsteriskNotRequired: true,
                    showError: false
                  },
                },
                {
                  key: 'hasNoFlushingAndHydrostaticTestEvidence',
                  type: 'rf-yes-no',
                  defaultValue: null,
                  wrappers: [ 'question-wrapper' ],
                  className: 'flushing-hydrostatic-section',
                  props: {
                    label: 'Test Evidence on File',
                    required: true,
                    isFontSizeSmall: true,
                    isAsteriskNotRequired: true,
                    showError: false
                  },
                  expressions: {
                    'props.isDisabled': (field: FormlyFieldConfig) => {
                      if (field?.model?.hasFlushingAndHydrostaticTests === false) {
                        field?.formControl?.setValue(false);
                        return true;
                      }
                      else {
                        return false;
                      }
                    },
                  },
                },
              ]
              //End Acceptance Testing Toggle
            },
          ],
        },
        OverAgeSprinklerTestSectionModel.build(formlyBuilderService).fields,
        AlarmSupervisoryTestSectionModel.build(formlyBuilderService).fields,
      ]
    });
  }
}
