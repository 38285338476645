import { Component, OnInit,OnDestroy, ViewChild } from '@angular/core';
import { IonContent, ModalController } from '@ionic/angular';
import { FieldType, FieldTypeConfig} from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { UtilService } from 'src/app/services/util-service/util.service';

@Component({
  selector: 'app-formly-comment',
  templateUrl: './formly-comment-fr-sheet.component.html',
  styleUrls: [ './formly-comment-fr-sheet.component.scss' ],
})
// Predominant
export class FormlyCommentComponent extends FieldType<FieldTypeConfig> implements OnInit, OnDestroy {
  @ViewChild(IonContent) content: IonContent;
  initialForm: any;
  fieldValue
  commentMaxLength: number;

  protected ngUnsubscribe = new Subject<void>();
  constructor(private modalCtrl: ModalController, private utilService: UtilService) {
    super();
  }

  ngOnInit() {
    this.fieldValue = this.field.props.initialValue ? this.field.props.initialValue :this.field.formControl?.get(this.field.props.commentActionKey)?.value;
    this.commentMaxLength = this.field?.props?.maxLength;
  }

  ngAfterViewInit() {
    this.utilService.setContentHeight(this.content, this.ngUnsubscribe);
  }

  onSwipeDown() {
    this.close();
  }
  close() {
    return this.modalCtrl.dismiss(this.fieldValue, 'cancel');
  }
  save() {
   
    return this.modalCtrl.dismiss(this.fieldValue, 'save');

  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
