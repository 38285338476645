/* eslint-disable prefer-const */
import { Injectable } from '@angular/core';
import { SectionServiceAbstract } from 'src/app/abstract/formly-builder/section-service.abstract';
import { RiskReportService } from '../risk-report.service';
import { InternalProtectionDataModel } from 'src/app/models/formly-builder/formly/full-risk/internal-protection-data.model';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { SectionEnum } from '../section.config';
import { distinctUntilChanged, startWith, takeUntil } from 'rxjs/operators';
import { FormlyBuilderService } from '../formly-builder.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class FormlyInternalProtectionService extends SectionServiceAbstract {
  isDisableCollapseIcon: boolean;

  constructor(private riskReportService: RiskReportService, private formlyBuilder: FormlyBuilderService) {
    super();
  }
  async updateData(model: any, riskReportData: any) {
    const hasChanged: boolean = InternalProtectionDataModel.hasChanged(model, riskReportData);
    if (hasChanged) {
      const internalProtectionData = InternalProtectionDataModel.to(model);
      internalProtectionData.InternalProtections[ 0 ].ReportIdentifier = internalProtectionData.InternalProtections[ 0 ].ReportIdentifier ?
        internalProtectionData.InternalProtections[ 0 ].ReportIdentifier : riskReportData.ReportIdentifier;
      return { ...riskReportData, ...internalProtectionData };
    } else {
      return undefined;
    }
  }

  loadData(sectionData: any[], reportInfo: any, isS3: boolean, model: any) {
    let internalProtectionDetails: InternalProtectionDataModel;
    if (isS3) {
      internalProtectionDetails = InternalProtectionDataModel.fromS3(reportInfo);
    } else {
      internalProtectionDetails = InternalProtectionDataModel.from(reportInfo);
    }
    return {
      ...model, ...{
        internalProtections: {
          interProtection: internalProtectionDetails?.interProtection,
          limitedSupplyFps: internalProtectionDetails?.limitedSupplyFps,
          automaticFireAlarmDetectionSystem: internalProtectionDetails?.automaticFireAlarmDetectionSystem,
          sprinklersData: internalProtectionDetails?.sprinklersData,
        }
      }
    };

  }
  calculateHasExstingUser() {
    const reportsOccupantsData = this.riskReportService?.report?.Occupants;
    let equalsValues = [];
    let returnedValue;
    if (reportsOccupantsData.length > 0) {
      reportsOccupantsData.forEach((array, index) => {
        const allEqual = (occupantLevels) => occupantLevels.every(val => val.HasExtinguisher === true);
        equalsValues.push(allEqual(array.OccupantLevels));
        const allEqual1 = (occupantLevels) => occupantLevels.every(val => val === true);
        returnedValue = allEqual1(equalsValues);
      });
      return returnedValue;
    }
    else {
      return false;
    }
  }
  enableOrDisableWatchmanType(field: FormlyFieldConfig) {
    let fieldval = field?.formControl?.value;
    if (fieldval !== null && fieldval !== undefined) {
      if (!fieldval) {
        field?.form?.get('watchmanType').setValue(null);
      }
    }
  }

  disableAndCollapseLimitedSupply(field: FormlyFieldConfig) {
    const occupantsList = this.riskReportService?.model?.occupants['section-occupants'].occupants ?? [];
    const occupantLevels = this.formlyBuilder?.occupantHazardsService?.getOccupantLevels(occupantsList);
    const occupantArr = occupantLevels.some(level => level.hasLimitedSupplyFireProtectionSystem);
    if (!occupantArr) {
      if (this.riskReportService?.model?.internalProtections?.limitedSupplyFps) {
        this.riskReportService.model.internalProtections.limitedSupplyFps.limitedSupplyAlarmType = null;
        this.riskReportService.model.internalProtections.limitedSupplyFps.limitedSupplyProtectedArea = null;
      }
      if (this.riskReportService?.report?.InternalProtections?.length && this.riskReportService.report.InternalProtections[ 0 ]) {
        this.riskReportService.report.InternalProtections[ 0 ].LimitedSupplyAlarmType = null;
        this.riskReportService.report.InternalProtections[ 0 ].LimitedSupplyProtectedArea = null;
      }
    } else {
      const limitedSupplyAreaControl = field?.form?.root?.get('internalProtections.limitedSupplyFps.limitedSupplyProtectedArea');
      if (limitedSupplyAreaControl && !limitedSupplyAreaControl.value) {
        const areaValue = this.totalLimitedSupplyAreaValue(field);
        if (this.riskReportService?.report?.InternalProtections?.length && this.riskReportService.report.InternalProtections[0]) {
          this.riskReportService.report.InternalProtections[0].LimitedSupplyProtectedArea = areaValue;
        }
      }

    }
    if (!occupantArr) {
      this.isDisableCollapseIcon = true;
    } else {
      this.isDisableCollapseIcon = false;
    }
  }

  checkOccupants(field) {
    const formValue = field.form?.root?.value;
    const occupantsList = formValue?.occupants[ 'section-occupants' ]?.occupants ?? [];
    const formlyService = field?.options?.formState?.service;
    const occupantLevels = formlyService?.occupantHazardsService?.getOccupantLevels(occupantsList);
    const occupantArr = occupantLevels.some(level => level.hasLimitedSupplyFireProtectionSystem);
    if (!occupantArr) {
      this.isDisableCollapseIcon = true;
    } else {
      this.isDisableCollapseIcon = false;
    }
  }

  isDisableCollapse(field) {
    this.checkOccupants(field);
    if (this.isDisableCollapseIcon) {
      return true;
    } else {
      return false;
    }
  }

  totalLimitedSupplyAreaValue(field) {
    let totalArea = 0;
    const formValue = field.form?.root?.value;
    const occupantsList = formValue?.occupants[ 'section-occupants' ]?.occupants ?? [];
    const formlyService = field.options.formState.service;
    const occupantLevels = formlyService.occupantHazardsService.getOccupantLevels(occupantsList);
    if (!this.isDisableCollapseIcon) {
      occupantLevels.forEach((floor) => {
        if (floor.hasLimitedSupplyFireProtectionSystem) {
          if (Number(floor.levelStop) > 0 && Number(floor.levelStart) < Number(floor.levelStop)) {
            totalArea += floor.areaPerLevel * (Number(floor.levelStop) - Number(floor.levelStart) + 1);
          } else {
            totalArea += floor.areaPerLevel;
          }
        }
      });
      if (totalArea === 0) {
        totalArea = null;
        return totalArea;
      } else {
        return totalArea;
      }
    }
  }

  //Automatic Fire Alarm Detection Systems
  autoFireDetectionReset(field: FormlyFieldConfig) {
    field.formControl?.valueChanges?.pipe(distinctUntilChanged())?.subscribe(src => {
      if (src === false) {
        field.form.get('autoFireAlarmSystemCoverageType').reset();
        field.form.get('autoFireAlarmSystemCoverageTypeComment').reset();
      }
    });
  }

  getSprinklerDeficiencyPoints() {
    let waterSpplyDefPts;
    const sprinklerDefiPoints = this.riskReportService?.model?.sprinklerReport?.defListV1;
    if (sprinklerDefiPoints !== undefined) {
      sprinklerDefiPoints[ 0 ]?.forEach((defs) => {
        if (defs.ChargeDescription === 'Primary supply percent deficiency') {
          waterSpplyDefPts = defs?.ChargePoints;
        }
      });
      const spComponentDefPts = this.riskReportService?.model?.sprinklerReport?.systemComponentPoints;
      const sysTestDefPts = this.riskReportService?.model?.sprinklerReport?.systemTestPoints;
      const defPoints = [ waterSpplyDefPts, spComponentDefPts, sysTestDefPts ];
      return defPoints;
    } else {
      return [];
    }

  }

  isScheduleNumReqMatch(field) {
    const hazard = field?.filter(occupant =>
      occupant.combustibilityClass == 5
    );
    const hazardScheduleNumber = hazard?.flatMap(occupant => occupant?.occupantHazards?.map(hazard => hazard?.scheduleNumber));
    const occupantScheduleNumber = field?.map(occupant =>
      occupant.occupantScheduleNo
    );
    const isHazardMatched = hazardScheduleNumber?.some(num => num?.toString()?.startsWith('731240000'));
    const isOccupantMatched = occupantScheduleNumber?.some(num => (num === '5040000000' || num === '5190000000' || num === '6038400000' || num === '5135000000' || num === '6015E00000'));
    if (isHazardMatched || isOccupantMatched) {
      return true;
    } else {
      return false;
    }
  }

  calculateArea(floors, levelTypeCode) {
    let totalArea = 0;
    floors.forEach((floor) => {
      if (floor && floor.levelTypeCode == levelTypeCode && floor.area && !floor.isAreaCalc) {
        if (Number(floor.levelStop) > 0 && Number(floor.levelStart) < Number(floor.levelStop)) {
          totalArea += floor.area * (Number(floor.levelStop) - Number(floor.levelStart) + 1);
        } else {
          totalArea += floor.area;
        }
      } else if (floor.isAreaCalc && floor.areaCalcLevelTypeCode !== 'ROOF') {
        totalArea += floor.width;
      }
    });
    return totalArea;
  }

  floorsRoofCalculation(floorsRoofData) {
    const floorLevels = floorsRoofData.slice(0,-1);
    const totalfloorArea = this.calculateArea(floorLevels, 'FLOO');
    const totalMezzArea = this.calculateArea(floorLevels, 'MEZZ');
    const totalBaseArea = this.calculateArea(floorLevels, 'BASE');
    const totalAttiArea = this.calculateArea(floorLevels, 'ATTI');
    const totalFloorsAreaOfBuilding = (totalfloorArea + totalMezzArea + totalBaseArea + totalAttiArea) / 4;
    const areaArrayList = [ totalfloorArea, totalMezzArea, totalBaseArea, totalAttiArea, totalFloorsAreaOfBuilding ];
    const areaArray = areaArrayList?.filter(num => num !== 0);
    return areaArray;
  }

  // isAreaRequirementMatched(field) {
  //   const floorsroofData = field?.form?.root?.value?.floorsAndRoofs
  //     .constructionEntries?.floorsAndRoofsLevels;
  //   const sprinklerArea = this.calculatePartialSupplyProtectedArea(field);
  //   let floorAreas = this.floorsRoofCalculation(floorsroofData);
  //   if(floorAreas.length === 0) {
  //     floorAreas = [0];
  //   }
  //   if (sprinklerArea && sprinklerArea > 0) {
  //     const isAreaCovers = floorAreas.some(area => sprinklerArea >= area);
  //     if (isAreaCovers) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     return false;
  //   }

  // }

  

  isDeficiencyRequirmentMatch() {
    const defPts = this.getSprinklerDeficiencyPoints();
    if (defPts.length >= 0 && defPts[ 0 ] !== undefined && defPts[ 0 ] !== null && defPts[ 1 ] !== undefined && defPts[ 1 ] !== null && defPts[ 2 ] !== undefined && defPts[ 2 ] !== null) {
      if (Number(defPts[ 0 ]) <= 80 && Number(defPts[ 1 ]) <= 100 && Number(defPts[ 2 ]) < 100) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  isNonSprDeficiencyRequirmentMatch() {
    const defPts = this.getSprinklerDeficiencyPoints();
    if (defPts.length >= 0 && defPts[ 1 ] !== undefined && defPts[ 1 ] !== null && defPts[ 2 ] !== undefined && defPts[ 2 ] !== null) {
      if (Number(defPts[ 1 ]) <= 100 && Number(defPts[ 2 ]) < 100) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  calculateDeficiencyPoints(field: FormlyFieldConfig) {
    field.formControl?.valueChanges?.pipe(takeUntil(this.ngUnsubscribe), startWith(''))?.subscribe(src => {
      const formValue = field.form?.value;
      if (formValue?.alarmSignalDestinationType && formValue?.alarmSignalDestinationType?.length > 0 &&
        formValue?.troubleSignalDestinationType && formValue?.troubleSignalDestinationType?.length > 0 &&
        formValue?.autoFireAlarmInspectionAndTestFrequencyType && formValue?.autoFireAlarmInspectionAndTestFrequencyType?.length > 0) {

        let deficiencyPoints = 0;
        deficiencyPoints += field.form?.get('isRecieverListed')?.value && field.form?.get('isRecieverListed')?.value === true ? 0 : 1;
        if (formValue?.alarmSignalDestinationType === 'OTHR' || formValue?.alarmSignalDestinationType === 'NOST' ||
          formValue?.alarmSignalDestinationType === 'CERT') {
          deficiencyPoints += 2;
        }
        if (formValue?.troubleSignalDestinationType === 'NONE') {
          deficiencyPoints += 2;
        } else if (formValue?.troubleSignalDestinationType === 'OTHR' || formValue?.troubleSignalDestinationType === 'FINO') {
          deficiencyPoints += 1;
        }
        if (formValue?.autoFireAlarmInspectionAndTestFrequencyType === 'QUAR') {
          deficiencyPoints += 1;
        } else if (formValue?.autoFireAlarmInspectionAndTestFrequencyType === 'SEMI') {
          deficiencyPoints += 2;
        } else if (formValue?.autoFireAlarmInspectionAndTestFrequencyType === 'NOIN') {
          deficiencyPoints += 5;
        }
        field.form.get('deficiencyPoints')?.setValue(deficiencyPoints);
        this.calculateGrade(field);
        field.options.formState.service.riskReport.model.internalProtections.automaticFireAlarmDetectionSystem = {
          ...field.form.value
        };
        field.options.formState.service.riskReport.model = {
          ...field.options.formState.service.riskReport.model
        };
      } else {
        field.form.get('deficiencyPoints')?.setValue(null);
        field.form.get('fireAlarmGrade')?.setValue(null);
      }
    });
  }

  calculateGrade(field: FormlyFieldConfig) {
    const deficiencyPoints = field.form.get('deficiencyPoints')?.value;
    let grade = 0;
    if (Number(deficiencyPoints) > 4 || field.form.get('isSystemAndTransmitterListed')?.value !== true) {
      grade = 0;
    } else {
      const autoFireAlarmSystemCoverageType = field.form.get('autoFireAlarmSystemCoverageType')?.value;
      if (autoFireAlarmSystemCoverageType === 'FULL') {
        if (Number(deficiencyPoints) <= 1) {
          grade += 1;
        } else if (Number(deficiencyPoints) === 2) {
          grade += 2;
        } else if (Number(deficiencyPoints) > 2 && Number(deficiencyPoints) <= 4) {
          grade += 3;
        }
      } else if (autoFireAlarmSystemCoverageType === 'PART') {
        if (Number(deficiencyPoints) <= 1) {
          grade += 4;
        } else if (Number(deficiencyPoints) === 2) {
          grade += 5;
        } else if (Number(deficiencyPoints) > 2 && Number(deficiencyPoints) <= 4) {
          grade += 6;
        }
      }
    }
    field.form.get('fireAlarmGrade')?.setValue(grade === 0 ? 'No Credit' : grade);
  }

  setAsgrRating(field) {
    const reportScoreV1 = this.riskReportService?.model?.sprinklerReport?.reportScore;
    const sprinklerDataControl = field?.form?.root?.get('internalProtections')?.get('sprinklersData');
    if(reportScoreV1 !== null && reportScoreV1 !== undefined) {
      sprinklerDataControl?.get('sprinklerRatingAsgr')?.setValue(Number(reportScoreV1));
    }
  }

  // isAreaRequirementMatchedModel(model, field) {
  //   const floorsroofData = model?.floorsAndRoofs?.constructionEntries?.floorsAndRoofsLevels;
  //   const sprinklerArea = this.calculatePartialSupplyProtectedArea(field, true, model);
  //   let floorAreas = this.floorsRoofCalculation(floorsroofData);
  //   if(floorAreas?.length === 0) {
  //     floorAreas = [0];
  //   }
  //   if (sprinklerArea && sprinklerArea > 0) {
  //     const isAreaCovers = floorAreas.some(area => sprinklerArea >= area);
  //     if (isAreaCovers) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     return false;
  //   }

  // }

  // Calculate sprinklerType after calculate button click
  async setSprinklerTypeFromModel(model, field) {
    if (model?.evidenceOfFireSprinkler) {
      const sprinklerRatingAsgr = model?.sprinklerReport?.reportScore;
      field?.form?.get('sprinklerRatingAsgr')?.setValue(sprinklerRatingAsgr);
      const isWaterSupplyEvaluated = !model?.sprinklerReport?.asgrWaterSupply?.isWaterSupplyNotEvaluated;
      const isScheduleNumReqMatch = this.isScheduleNumReqMatch(model?.occupants["section-occupants"]?.occupants);
      const hasAdequatePressureOnMainDrainTest = model?.sprinklerReport?.asgrWaterSupply?.hasAdequatePressureOnMainDrainTest;
      const isSsReqMatch = (model?.sprinklerReport?.asgrWaterSupply?.supplySource?.ss1?.supplySourceTypeSs1 !== 'None Available' && model?.sprinklerReport?.asgrWaterSupply?.supplySource?.ss1?.supplySourceTypeSs1 !== '' && model?.sprinklerReport?.asgrWaterSupply?.supplySource?.ss1?.supplySourceTypeSs1 !== null) ? true : false;
      const isDefReqMatched = this.isDeficiencyRequirmentMatch();
      const isNonSprDeficiencyRequirmentMatch = this.isNonSprDeficiencyRequirmentMatch();
      // const isOneEntireLevelIsSprinklered = this.isAreaRequirementMatchedModel(model, field);
      const isSprinkleredAreaGreaterThan25Percent = this.isSprinkleredAreaGreaterThan25Percent(field);
      let isOneEntireLevelIsSprinklered = false;
      if(!isSprinkleredAreaGreaterThan25Percent) {
        isOneEntireLevelIsSprinklered = this.isOneEntrieLevelIsSprinklered(field);
      }
      const isAreaReqMatched = isOneEntireLevelIsSprinklered || isSprinkleredAreaGreaterThan25Percent;
      if (model?.internalProtections?.sprinklersData) {
        if (sprinklerRatingAsgr >= 10) {
          model.internalProtections.sprinklersData.sprinklerTypeCodeValue = 'S';
           model.internalProtections.sprinklersData.sprinklerTypeValue = 'Sprinklered';
          field?.form?.get('sprinklerTypeCodeValue')?.setValue('S');
          field.props.isWarningEnabled = false;
        } else if (sprinklerRatingAsgr >= 1 && sprinklerRatingAsgr <= 9 && isAreaReqMatched && !isScheduleNumReqMatch) {
          model.internalProtections.sprinklersData.sprinklerTypeCodeValue = 'P';
           model.internalProtections.sprinklersData.sprinklerTypeValue = 'Partially Sprinklered';
          field?.form?.get('sprinklerTypeCodeValue')?.setValue('P');
          field.props.isWarningEnabled = false;
        } else if (sprinklerRatingAsgr >= 1 && sprinklerRatingAsgr <= 9 && !isAreaReqMatched) {
        field.props.warningMessageContent = 'This risk does not meet the minimum requirements per SCOPES to receive partial sprinklered credit, please verify your sprinkler area on the sprinkler report and the risk report';
        field.props.isWarningEnabled = true;
          model.internalProtections.sprinklersData.sprinklerTypeCodeValue = 'N';
           model.internalProtections.sprinklersData.sprinklerTypeValue = 'Non-Sprinklered';
          field?.form?.get('sprinklerTypeCodeValue')?.setValue('N');
        } else if (sprinklerRatingAsgr === 0 && isWaterSupplyEvaluated && isAreaReqMatched && isDefReqMatched && !isScheduleNumReqMatch) {
          field.props.isWarningEnabled = false;
          model.internalProtections.sprinklersData.sprinklerTypeCodeValue = 'P';
           model.internalProtections.sprinklersData.sprinklerTypeValue = 'Partially Sprinklered';
          field?.form?.get('sprinklerTypeCodeValue')?.setValue('P');
        } else if (sprinklerRatingAsgr === 0 && isWaterSupplyEvaluated && (!isAreaReqMatched || !isDefReqMatched)) {
          field.props.warningMessageContent = 'This risk does not meet the minimum requirements per SCOPES to receive partial sprinklered credit, this risk is nonsprinklered';
          field.props.isWarningEnabled = true;
          model.internalProtections.sprinklersData.sprinklerTypeCodeValue = 'N';
           model.internalProtections.sprinklersData.sprinklerTypeValue = 'Non-Sprinklered';
          field?.form?.get('sprinklerTypeCodeValue')?.setValue('N');
        } else if (sprinklerRatingAsgr === 0 && !isWaterSupplyEvaluated && hasAdequatePressureOnMainDrainTest && isSsReqMatch && isAreaReqMatched && isNonSprDeficiencyRequirmentMatch && !isScheduleNumReqMatch) {
          model.internalProtections.sprinklersData.sprinklerTypeCodeValue = 'P';
           model.internalProtections.sprinklersData.sprinklerTypeValue = 'Partially Sprinklered';
          field?.form?.get('sprinklerTypeCodeValue')?.setValue('P');
        } else if (sprinklerRatingAsgr === 0 && !isWaterSupplyEvaluated && hasAdequatePressureOnMainDrainTest && isSsReqMatch &&(!isAreaReqMatched || !isNonSprDeficiencyRequirmentMatch)) {
          field.props.warningMessageContent = 'This risk does not meet the minimum requirements per SCOPES to receive partial sprinklered credit, this risk is nonsprinklered';
          field.props.isWarningEnabled = true;
          model.internalProtections.sprinklersData.sprinklerTypeCodeValue = 'N';
           model.internalProtections.sprinklersData.sprinklerTypeValue = 'Non-Sprinklered';
          field?.form?.get('sprinklerTypeCodeValue')?.setValue('N');
        } else if (sprinklerRatingAsgr >= 1 && sprinklerRatingAsgr <= 9 && isAreaReqMatched && isScheduleNumReqMatch) {
          field.props.isWarningEnabled = false;
          model.internalProtections.sprinklersData.sprinklerTypeCodeValue = 'N';
           model.internalProtections.sprinklersData.sprinklerTypeValue = 'Non-Sprinklered';
          field?.form?.get('sprinklerTypeCodeValue')?.setValue('N');
        } else if (sprinklerRatingAsgr === 0 && isWaterSupplyEvaluated && isAreaReqMatched && isDefReqMatched && isScheduleNumReqMatch) {
          field.props.isWarningEnabled = false;
          model.internalProtections.sprinklersData.sprinklerTypeCodeValue = 'N';
           model.internalProtections.sprinklersData.sprinklerTypeValue = 'Non-Sprinklered';
          field?.form?.get('sprinklerTypeCodeValue')?.setValue('N');
        } else if (sprinklerRatingAsgr === 0 && !isWaterSupplyEvaluated && hasAdequatePressureOnMainDrainTest && isSsReqMatch && isAreaReqMatched && isNonSprDeficiencyRequirmentMatch && isScheduleNumReqMatch) {
          field.props.isWarningEnabled = false;
          model.internalProtections.sprinklersData.sprinklerTypeCodeValue = 'N';
           model.internalProtections.sprinklersData.sprinklerTypeValue = 'Non-Sprinklered';
          field?.form?.get('sprinklerTypeCodeValue')?.setValue('N');
        } else if (sprinklerRatingAsgr === 0 && !isWaterSupplyEvaluated && hasAdequatePressureOnMainDrainTest && !isSsReqMatch) {
          field.props.isWarningEnabled = false;
          model.internalProtections.sprinklersData.sprinklerTypeCodeValue = 'N';
           model.internalProtections.sprinklersData.sprinklerTypeValue = 'Non-Sprinklered';
          field?.form?.get('sprinklerTypeCodeValue')?.setValue('N');
        } else if (sprinklerRatingAsgr === 0 && !isWaterSupplyEvaluated && !hasAdequatePressureOnMainDrainTest) {
          field.props.isWarningEnabled = false;
          model.internalProtections.sprinklersData.sprinklerTypeCodeValue = 'N';
           model.internalProtections.sprinklersData.sprinklerTypeValue = 'Non-Sprinklered';
          field?.form?.get('sprinklerTypeCodeValue')?.setValue('N');
        } else if (sprinklerRatingAsgr === 0 && !isWaterSupplyEvaluated && hasAdequatePressureOnMainDrainTest === null) {
          field.props.isWarningEnabled = false;
          model.internalProtections.sprinklersData.sprinklerTypeCodeValue = 'N';
           model.internalProtections.sprinklersData.sprinklerTypeValue = 'Non-Sprinklered';
          field?.form?.get('sprinklerTypeCodeValue')?.setValue('N');
        }

        field.options.formState.isSprinklerTypeCodeWarningEnabled = field.props.isWarningEnabled; 

        //Updating the report with latest sprinklerTypeCodeValue
        const sprinklerTypeCodeValue = model?.internalProtections?.sprinklersData?.sprinklerTypeCodeValue;
        if (sprinklerTypeCodeValue && this.riskReportService?.report?.SprinklerTypeCodeValue !== sprinklerTypeCodeValue) {
          this.riskReportService.report.SprinklerTypeCodeValue = sprinklerTypeCodeValue;
        }

        if (this.riskReportService?.report?.SprinklerTypeCodeValue && (this.riskReportService.report.SprinklerTypeCodeValue === 'N' ||
          this.riskReportService.report.SprinklerTypeCodeValue === 'P')) {
          if (this.riskReportService?.report?.InternalProtections?.length) {
            this.riskReportService.report.InternalProtections[0].SprinkleredStationType = "NONE";
            this.riskReportService.report.InternalProtections[0].HasNonStandardRetransmission = false;
            this.riskReportService.report.InternalProtections[0].SprinkleredStationAlarmType = "NONE";
            this.riskReportService.report.InternalProtections[0].HasStandardPeriodicInspectionService = false;
          }
        }

        const partialSupplyProtectedArea = this.calculatePartialSupplyProtectedArea(field);
        if(this.riskReportService?.report?.InternalProtections?.length) {
          if (this.riskReportService?.report?.SprinklerTypeCodeValue === 'P' ||
            (partialSupplyProtectedArea && this.riskReportService?.report?.SprinklerTypeCodeValue === 'N' && field.props.isWarningEnabled)) {
            this.riskReportService.report.InternalProtections[0].PartialSupplyProtectedArea = partialSupplyProtectedArea;
          } else {
            this.riskReportService.report.InternalProtections[0].PartialSupplyProtectedArea = null;
          }
        }
        
      }
    }
  }

  setSprinklerType(field) {
    const formValue = field?.form?.root?.value;
    const sprinklerDataControl = field?.form?.root?.get('internalProtections')?.get('sprinklersData');
    const isWaterSupplyEvaluated = !field?.form?.root?.value?.sprinklerReport?.asgrWaterSupply?.isWaterSupplyNotEvaluated;
    const isScheduleNumReqMatch = this.isScheduleNumReqMatch(field.form?.root?.value?.occupants[ 'section-occupants' ]?.occupants);
    const hasAdequatePressureOnMainDrainTest = field?.form?.root?.value?.sprinklerReport?.asgrWaterSupply?.hasAdequatePressureOnMainDrainTest;
    const isSsReqMatch = (formValue?.sprinklerReport?.asgrWaterSupply?.supplySource?.ss1?.supplySourceTypeSs1 !== 'None Available' && formValue?.sprinklerReport?.asgrWaterSupply?.supplySource?.ss1?.supplySourceTypeSs1 !== '' && formValue?.sprinklerReport?.asgrWaterSupply?.supplySource?.ss1?.supplySourceTypeSs1 !== null) ? true : false;
    const isEvidanceOfFireSprinkler = (field.form.root.value.evidenceOfFireSprinkler === 'true' || field.form.root.value.evidenceOfFireSprinkler === true) ? true : false;
    const isDefReqMatched = this.isDeficiencyRequirmentMatch();
    const isNonSprDeficiencyRequirmentMatch = this.isNonSprDeficiencyRequirmentMatch();
    // const isAreaReqMatched = this.isAreaRequirementMatched(field);
    const isSprinkleredAreaGreaterThan25Percent = this.isSprinkleredAreaGreaterThan25Percent(field);
    let isOneEntireLevelIsSprinklered = false;
    if(!isSprinkleredAreaGreaterThan25Percent) {
      isOneEntireLevelIsSprinklered = this.isOneEntrieLevelIsSprinklered(field);
    }
    const isAreaReqMatched = isOneEntireLevelIsSprinklered || isSprinkleredAreaGreaterThan25Percent;

    if (sprinklerDataControl.value.sprinklerRatingAsgr >= 10) {
      field.fieldGroup[ 1 ].fieldGroup[ 1 ].props.isWarningEnabled = false;
      sprinklerDataControl?.get('sprinklerTypeValue')?.setValue('Sprinklered');
      this.riskReportService.report.SprinklerTypeCodeValue = 'S';
    } else if (sprinklerDataControl.value.sprinklerRatingAsgr >= 1 && sprinklerDataControl.value.sprinklerRatingAsgr <= 9 && isAreaReqMatched && !isScheduleNumReqMatch) {
      field.fieldGroup[ 1 ].fieldGroup[ 1 ].props.isWarningEnabled = false;
      sprinklerDataControl?.get('sprinklerTypeValue')?.setValue('Partially Sprinklered');
      this.riskReportService.report.SprinklerTypeCodeValue = 'P';
    } else if (sprinklerDataControl.value.sprinklerRatingAsgr >= 1 && sprinklerDataControl.value.sprinklerRatingAsgr <= 9 && !isAreaReqMatched) {
      field.fieldGroup[ 1 ].fieldGroup[ 1 ].props.warningMessageContent = 'This risk does not meet the minimum requirements per SCOPES to receive partial sprinklered credit, please verify your sprinkler area on the sprinkler report and the risk report';
      field.fieldGroup[ 1 ].fieldGroup[ 1 ].props.isWarningEnabled = true;
      sprinklerDataControl?.get('sprinklerTypeValue')?.setValue('Non-Sprinklered');
      this.riskReportService.report.SprinklerTypeCodeValue = 'N';
    } else if (sprinklerDataControl.value.sprinklerRatingAsgr === 0 && isWaterSupplyEvaluated && isAreaReqMatched && isDefReqMatched && !isScheduleNumReqMatch) {
      field.fieldGroup[ 1 ].fieldGroup[ 1 ].props.isWarningEnabled = false;
      sprinklerDataControl?.get('sprinklerTypeValue')?.setValue('Partially Sprinklered');
      this.riskReportService.report.SprinklerTypeCodeValue = 'P';
    } else if (sprinklerDataControl.value.sprinklerRatingAsgr === 0 && isWaterSupplyEvaluated && (!isAreaReqMatched || !isDefReqMatched)) {
      field.fieldGroup[ 1 ].fieldGroup[ 1 ].props.warningMessageContent = 'This risk does not meet the minimum requirements per SCOPES to receive partial sprinklered credit, this risk is nonsprinklered';
      field.fieldGroup[ 1 ].fieldGroup[ 1 ].props.isWarningEnabled = true;
      sprinklerDataControl?.get('sprinklerTypeValue')?.setValue('Non-Sprinklered');
      this.riskReportService.report.SprinklerTypeCodeValue = 'N';
    } else if (sprinklerDataControl.value.sprinklerRatingAsgr === 0 && !isWaterSupplyEvaluated && hasAdequatePressureOnMainDrainTest && isSsReqMatch && isAreaReqMatched && isNonSprDeficiencyRequirmentMatch && !isScheduleNumReqMatch) {
      field.fieldGroup[ 1 ].fieldGroup[ 1 ].props.isWarningEnabled = false;
      sprinklerDataControl?.get('sprinklerTypeValue')?.setValue('Partially Sprinklered');
      this.riskReportService.report.SprinklerTypeCodeValue = 'P';
    } else if (sprinklerDataControl.value.sprinklerRatingAsgr === 0 && !isWaterSupplyEvaluated && hasAdequatePressureOnMainDrainTest && isSsReqMatch && (!isAreaReqMatched || !isNonSprDeficiencyRequirmentMatch)) {
      field.fieldGroup[ 1 ].fieldGroup[ 1 ].props.warningMessageContent = 'This risk does not meet the minimum requirements per SCOPES to receive partial sprinklered credit, this risk is nonsprinklered';
      field.fieldGroup[ 1 ].fieldGroup[ 1 ].props.isWarningEnabled = true;
      sprinklerDataControl?.get('sprinklerTypeValue')?.setValue('Non-Sprinklered');
      this.riskReportService.report.SprinklerTypeCodeValue = 'N';
    } else if (sprinklerDataControl.value.sprinklerRatingAsgr >= 1 && sprinklerDataControl.value.sprinklerRatingAsgr <= 9 && isAreaReqMatched && isScheduleNumReqMatch) {
      field.fieldGroup[ 1 ].fieldGroup[ 1 ].props.isWarningEnabled = false;
      sprinklerDataControl?.get('sprinklerTypeValue')?.setValue('Non-Sprinklered');
      this.riskReportService.report.SprinklerTypeCodeValue = 'N';
    } else if (sprinklerDataControl.value.sprinklerRatingAsgr === 0 && isWaterSupplyEvaluated && isAreaReqMatched && isDefReqMatched && isScheduleNumReqMatch) {
      field.fieldGroup[ 1 ].fieldGroup[ 1 ].props.isWarningEnabled = false;
      sprinklerDataControl?.get('sprinklerTypeValue')?.setValue('Non-Sprinklered');
      this.riskReportService.report.SprinklerTypeCodeValue = 'N';
    } else if (sprinklerDataControl.value.sprinklerRatingAsgr === 0 && !isWaterSupplyEvaluated && hasAdequatePressureOnMainDrainTest && isSsReqMatch && isAreaReqMatched && isNonSprDeficiencyRequirmentMatch && isScheduleNumReqMatch) {
      field.fieldGroup[ 1 ].fieldGroup[ 1 ].props.isWarningEnabled = false;
      sprinklerDataControl?.get('sprinklerTypeValue')?.setValue('Non-Sprinklered');
      this.riskReportService.report.SprinklerTypeCodeValue = 'N';
    } else if (sprinklerDataControl.value.sprinklerRatingAsgr === 0 && !isWaterSupplyEvaluated && hasAdequatePressureOnMainDrainTest && !isSsReqMatch) {
      field.fieldGroup[ 1 ].fieldGroup[ 1 ].props.isWarningEnabled = false;
      sprinklerDataControl?.get('sprinklerTypeValue')?.setValue('Non-Sprinklered');
      this.riskReportService.report.SprinklerTypeCodeValue = 'N';
    } else if (sprinklerDataControl.value.sprinklerRatingAsgr === 0 && !isWaterSupplyEvaluated && !hasAdequatePressureOnMainDrainTest) {
      field.fieldGroup[ 1 ].fieldGroup[ 1 ].props.isWarningEnabled = false;
      sprinklerDataControl?.get('sprinklerTypeValue')?.setValue('Non-Sprinklered');
      this.riskReportService.report.SprinklerTypeCodeValue = 'N';
    } else if (sprinklerDataControl.value.sprinklerRatingAsgr === 0 && !isWaterSupplyEvaluated && hasAdequatePressureOnMainDrainTest === null) {
      field.fieldGroup[ 1 ].fieldGroup[ 1 ].props.isWarningEnabled = false;
      sprinklerDataControl?.get('sprinklerTypeValue')?.setValue('Non-Sprinklered');
      this.riskReportService.report.SprinklerTypeCodeValue = 'N';
    }

    field.options.formState.isSprinklerTypeCodeWarningEnabled = field.fieldGroup[ 1 ].fieldGroup[ 1 ].props.isWarningEnabled; 

    if (this.riskReportService?.report?.SprinklerTypeCodeValue && (this.riskReportService.report.SprinklerTypeCodeValue === 'N' ||
      this.riskReportService.report.SprinklerTypeCodeValue === 'P')) {
      if (this.riskReportService?.report?.InternalProtections?.length) {
        this.riskReportService.report.InternalProtections[0].SprinkleredStationType = "NONE";
        this.riskReportService.report.InternalProtections[0].HasNonStandardRetransmission = false;
        this.riskReportService.report.InternalProtections[0].SprinkleredStationAlarmType = "NONE";
        this.riskReportService.report.InternalProtections[0].HasStandardPeriodicInspectionService = false;
      }
    }
  }

  sprinklerTypeValidation2Check(field) {
    const occupants = field.form?.root?.value?.occupants[ 'section-occupants' ]?.occupants;
    const occupantScheduleNumber = occupants?.map(occupant =>
      occupant.occupantScheduleNo
    );
    const formlyService = field?.options?.formState?.service?.riskReport?.model;
    const hydraulicDesignStandard = formlyService?.sprinklerReport?.asgrWaterSupply?.hydraulicDesignStandard;
    const isScheduleNumberMatch = occupantScheduleNumber?.some(value => value?.toString().startsWith('524'));
    if (hydraulicDesignStandard === 'NFPA13R' && !isScheduleNumberMatch && (field.formControl.value === 'Sprinklered' || field.formControl.value === 'Partially Sprinklered')) {
      return true;
    } else {
      return false;
    }
  }

  sprinklerTypeValidation3Check(field) {
    const formValue = field.form?.root?.value;
    const occupantsList = formValue?.occupants[ 'section-occupants' ]?.occupants ?? [];
    const formlyService = field?.options?.formState?.service;
    const occupantLevels = formlyService?.occupantHazardsService?.getOccupantLevels(occupantsList);
    const occupantArr = occupantLevels?.every(level => level.sprinklerDesign === '13R' && level.sprinklerType === 'Non-Creditable');
    if (occupantArr && (field.formControl.value === 'Sprinklered' || field.formControl.value === 'Partially Sprinklered')) {
      return true;
    } else {
      return false;
    }
  }

  sprinklerTypeValidation4Check(field) {
    const occupants = field.form?.root?.value?.occupants[ 'section-occupants' ]?.occupants;
    const occupantScheduleNumber = occupants?.map(occupant =>
      occupant.occupantScheduleNo
    );
    const isScheduleNumberMatch = occupantScheduleNumber?.some(value => value?.toString().startsWith('524'));
    const formValue = field?.form?.root?.value;
    const occupantsList = formValue?.occupants[ 'section-occupants' ]?.occupants ?? [];
    const formlyService = field?.options?.formState?.service;
    const occupantLevels = formlyService?.occupantHazardsService?.getOccupantLevels(occupantsList);
    const occupantArr = occupantLevels?.every(level => level.sprinklerDesign === '13R');
    if (occupantArr && !isScheduleNumberMatch && (field.formControl.value === 'Sprinklered' || field.formControl.value === 'Partially Sprinklered')) {
      return true;
    } else {
      return false;
    }
  }

  disableSprinkler(field) {
    if (field?.form?.root?.value?.evidenceOfFireSprinkler === false || field?.form?.root?.value?.evidenceOfFireSprinkler === 'false' || field?.form?.root?.value?.evidenceOfFireSprinkler === null) {
      return true;
    } else {
      return false;
    }
  }

  removeValuesSprinklersData(field, onLoad = false) {
   
    if (field?.form?.root?.value?.evidenceOfFireSprinkler === false || field?.form?.root?.value?.evidenceOfFireSprinkler === 'false' || field?.form?.root?.value?.isEvidanceOfFireSprinkler === null) {
      field.model.internalProtections.sprinklersData.sprinklerTypeCodeValue = 'N';
      field.model.internalProtections.sprinklersData.sprinklerTypeValue = '';
      field.model.internalProtections.sprinklersData.partialSupplyAlarmType = null;
      field.model.internalProtections.sprinklersData.sprinkleredStationType = null;
      field.model.internalProtections.sprinklersData.sprinkleredStationAlarmType = null;
      field.model.internalProtections.sprinklersData.partialSupplyProtectedArea = null;
      field.model.internalProtections.sprinklersData.hasNonStandardRetransmission = null;
      field.model.internalProtections.sprinklersData.hasStandardPeriodicInspectionService = null;
      field.form?.get('internalProtections').get('sprinklersData')?.get('sprinklerTypeValue')?.reset();
      field.form?.get('internalProtections').get('sprinklersData')?.get('partialSupplyAlarmType')?.reset();
      field.form?.get('internalProtections').get('sprinklersData')?.get('sprinkleredStationType')?.reset();
      field.form?.get('internalProtections').get('sprinklersData')?.get('sprinkleredStationAlarmType')?.reset();
      field.form?.get('internalProtections').get('sprinklersData')?.get('sprinklerRatingAsgr')?.reset();
      field.form?.get('internalProtections').get('sprinklersData')?.get('partialSupplyProtectedArea')?.reset();
      field.form?.get('internalProtections').get('sprinklersData')?.get('hasNonStandardRetransmission')?.reset();
      field.form?.get('internalProtections').get('sprinklersData')?.get('hasStandardPeriodicInspectionService')?.reset();
      field.options.formState.service.riskReport.model= {
        ...field.options.formState.service.riskReport.model
      };
      this.formlyBuilder.statusLineVisibility = false;
      this.riskReportService.updateSectionData(SectionEnum.FR_INTERNAL_PROTECTION_DETAILS, false,
        SectionEnum.FR_INTERNAL_PROTECTION_DETAILS, null);
    }
  }

  // If all Extinguishers are checked inside occupant, then internal protection Extinguishers will be checked
  updateInternalProtectionData() {
    const occupants = this.riskReportService?.model?.occupants['section-occupants']?.occupants;
    let hasExtinguisher;
    if (occupants?.length) {
      for (const occupant of occupants) {
        if (occupant?.occupantLevels?.length && !occupant?.isDeleted) {
          hasExtinguisher = occupant.occupantLevels.every(val => val.hasExtinguisher === true);
        }
        if(!hasExtinguisher) {
          break;
        }
      }
    }
    if (this.riskReportService?.report?.InternalProtections?.length && this.riskReportService?.report?.InternalProtections[0]) {
      this.riskReportService.report.InternalProtections[0].HasExtinguishers = hasExtinguisher;
    }
  }

  /**
   * To calculate the PartialSprinkleredArea
   * @param {FormlyFieldConfig} field
   * @param {boolean} [isModelCalculation=false]
   * @param {*} [model]
   * @return {*} 
   * @memberof FormlyInternalProtectionService
   */
  calculatePartialSupplyProtectedArea(field: FormlyFieldConfig, isModelCalculation = false, model?:any ) {
    let partialSupplyProtectedArea = 0;
    let occupantsList = [];
    if(!isModelCalculation) {
      occupantsList = field.form?.root?.value?.occupants && field.form?.root?.value?.occupants[ 'section-occupants' ]?.occupants ?
      field.form?.root?.value?.occupants[ 'section-occupants' ]?.occupants : [];
    } else {
      occupantsList = model?.occupants && model?.occupants["section-occupants"]?.occupants ? model?.occupants["section-occupants"]?.occupants: [];
    }
    const occupantLevels =  field.options?.formState?.service?.occupantHazardsService?.getOccupantLevels(occupantsList);
    if(occupantLevels?.length) {
      occupantLevels.forEach((level) => {
        if(level) {
          if (!(level.sprinklerDesign === 'None' || (level.sprinklerDesign === "13R" && level.sprinklerType === "Non-Creditable") || 
            (level.sprinklerType === "Shut Off"))) {
            if (Number(level.levelStop) > 0 && Number(level.levelStart) && Number(level.levelStart) < Number(level.levelStop)) {
              partialSupplyProtectedArea += level.areaPerLevel * (Number(level.levelStop) - Number(level.levelStart) + 1);
            } else {
              partialSupplyProtectedArea += level.areaPerLevel;
            }
          }
        }
      });
      return partialSupplyProtectedArea;
    }    
    return null;
  }


  /**
   * To check whether the sprinkler area is Greater than 25 % of total Area
   * @param {FormlyFieldConfig} field
   * @return {boolean} will return true if condition satisfies Otherwise false
   * @memberof FormlyInternalProtectionService
   */
  isSprinkleredAreaGreaterThan25Percent(field: FormlyFieldConfig): boolean {
    const sprinklerArea = this.calculatePartialSupplyProtectedArea(field); // To calculate total SprinklerArea
    let totalArea = field?.form?.root?.value.occupants &&
      field?.form?.root?.value.occupants['section-occupants'].totalArea ?
      field?.form?.root?.value.occupants['section-occupants'].totalArea : 0;

    if ((sprinklerArea / totalArea) * 100 >= 25) {
      return true;
    }
    return false;
  }
  /**
   * Sprinklered area protects one entire horizontal level # (LevelType = floor, basement, attic or chargeable mezzanine)
   * to check whether any of the entrie one row is sprinklered or not (floor, Basement, Attic, Chargeable Mezzanine)
   * @param {*} field
   * @return {boolean} will return true if condition satisfies Otherwise false
   * @memberof FormlyInternalProtectionService
   */
  isOneEntrieLevelIsSprinklered(field): boolean {
    const occupantsList = field.form?.root?.value?.occupants && field.form?.root?.value?.occupants['section-occupants']?.occupants ?
      field.form?.root?.value?.occupants['section-occupants']?.occupants : [];
    let allLevels = [];
    let floorArray = [];
    let mezzanineArray = [];
    let isOneEntireLevelIsSprinklered = false;

    // categorizing all occupant levels based on levelstart
    if (occupantsList && occupantsList?.length) {
      occupantsList.forEach((occupant) => {
        if (!occupant.isDeleted) {
          if (occupant?.occupantLevels?.length) {
            occupant.occupantLevels.forEach((level) => {
              if (level) {
                if (level.levelStart && level.levelStop && Number(level.levelStop) > Number(level.levelStart)) {
                  for (let i = level.levelStart; i <= level.levelStop; i++) {
                    // Levels with Level start and level stop will goes under this
                    if (level.name === 'Mezzanine') {
                      // Lets push all Mezzanine entries to the list
                      mezzanineArray.push(this.createLevelEntry(level, i));
                    } else {
                      // Lets push all non Mezzanine entries to the list
                      allLevels.push(this.createLevelEntry(level, i));
                    }
                    if (level.name === 'Floor') {
                      // Lets push all Floor entries to the list
                      floorArray.push(this.createLevelEntry(level, i));
                    }
                  }
                } else if (level.levelStart && !level.levelStop) {
                  // Levels with Level start and no level stop will goes under this
                  if (level.name === 'Mezzanine') {
                    // Lets push all Mezzanine entries to the list
                    mezzanineArray.push(this.createLevelEntry(level, Number(level.levelStart)));
                  } else {
                    // Lets push all non Mezzanine entries to the list
                    allLevels.push(this.createLevelEntry(level, Number(level.levelStart)));
                  }
                  if (level.name === 'Floor') {
                    // Lets push all Floor entries to the list
                    floorArray.push(this.createLevelEntry(level, Number(level.levelStart)));
                  }
                }
              }
            });
          }
        }
      });
    }

    if(mezzanineArray?.length) {
      // calculates Chargeable Mezzanine and push them to allLevels array
      allLevels = this.calculateChargeableMezzanine(mezzanineArray, floorArray, allLevels);
    }

    // group All levels Based on Level start value
    allLevels = _.groupBy(allLevels, 'levelStart');
    if (Object.keys(allLevels)?.length) {
      isOneEntireLevelIsSprinklered = Object.keys(allLevels).some((key) => {
        // Group each Level start group based on the level Type 
        allLevels[key] = _.groupBy(allLevels[key], 'levelType');
        if (Object.keys(allLevels[key])?.length) {
          return Object.keys(allLevels[key]).some((type) => {
            let sprinklerArea = 0;
            let totalArea = 0;
            allLevels[key][type].forEach((level) => {
              if (!(level.sprinklerDesign === 'None' || (level.sprinklerDesign === "13R" && level.sprinklerType === "Non-Creditable") ||
                (level.sprinklerType === "Shut Off"))) {
                // sprinklerArea per one horizontal  level
                sprinklerArea += level.area;
              }
              // totalArea Per one horizontal level
              totalArea += level.area;
            });

            if (totalArea === sprinklerArea) {
              // If any one of the entire horizontal Level area is Sprinklered then return true
              return true;
            }
            return false;
          });
        }
        return false;
      });
    }

    return isOneEntireLevelIsSprinklered;
  }

  /**
   * creates a level entry with required properties
   * @param {*} level
   * @return {*} 
   * @memberof FormlyInternalProtectionService
   */
  createLevelEntry(level: any, levelStart: number) {
    return {
      levelType: level.name,
      levelStart: levelStart,
      area: level.areaPerLevel,
      sprinklerDesign: level.sprinklerDesign,
      sprinklerType: level.sprinklerType
    }
  }
  /**
   * Will calculate whether the mezzanine level is Chargeable or not,  
   * Push the chargeable Mezzanine entry to allLevels array and return it
   * @param {*} mezzanineArray
   * @param {*} floorArray
   * @param {*} allLevels
   * @return {any[]} 
   * @memberof FormlyInternalProtectionService
   */
  calculateChargeableMezzanine(mezzanineArray, floorArray, allLevels): any[] {
    // Summarize all Mezzanine levels based on level Start
    let summarizedMezzArray = [];
    if (mezzanineArray.length > 1) {
      mezzanineArray.forEach((item) => {
        const obj = summarizedMezzArray.find((o) => o.levelStart === item.levelStart);
        if (obj) { obj.area = obj.area + item.area; }
        else { summarizedMezzArray.push(item); }
      });
    } else {
      summarizedMezzArray = mezzanineArray;
    }

    // if the Mezzanine is Charagble then push them to allLevels array
    summarizedMezzArray.forEach((mezzLevel) => {
      let totalFloorArea = 0;
      floorArray.forEach((eachLevel) => {
        if (eachLevel.levelStart === mezzLevel.levelStart) {
          totalFloorArea += eachLevel.area;
        }
      });
      const flag = (mezzLevel.area / totalFloorArea) * 100 >= 25 ? true : false;
      if (flag) {
        mezzLevel.isCharagbleMezz = true;
        allLevels.push(mezzLevel);
      }
    });
    return allLevels;
  }

}
